import { gql } from "apollo-boost";

export const REQUEST_QUOTE = gql`
  mutation RequestQuote($input: RequestQuoteInput!) {
    requestQuote(input: $input)
  }
`

export const REQUEST_VISIT = gql`
  mutation RequestVisit($input: RequestVisitInput!) {
    requestVisit(input: $input)
  }
`