import { Layout, Spin } from 'antd';
import React from 'react';
import { loadingPageStyles } from './styles';

const {Content} = Layout

export const LoadingPage = () => {
  const styles = loadingPageStyles();
  return (
    <Content className={styles.container}>
      <Spin size='large' />
      <p>Loading...</p>
    </Content>
  )
  
}