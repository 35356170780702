import { createUseStyles } from 'react-jss';
import { base, colors, h3, spacing } from '../../styles';

export const dashboardStyles = createUseStyles({
  totalContainer: {
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  container: {
    marginTop: spacing.medium
  },
  factCard: {
    background: colors.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: base(10)
  },
  factCardText: {
    color: colors.black,
    fontWeight: 600,
    fontSize: h3.fontSize
  },
  infoCard: {
    background: colors.black,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: base(4),
    width: "100%",
    textAlign: "center"
  },
  escalationCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: base(4),
    width: "100%",
    textAlign: "center"
  },
  infoCardText: {
    color: colors.white,
    fontWeight: 600,
    fontSize: h3.fontSize
  },
})