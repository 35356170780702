import { useMutation } from '@apollo/react-hooks';
import { Affix, Layout } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppHeader, AppSidebar, LoadingPage } from './lib/components';
import { LOG_IN } from './lib/graphql/mutation/LogIn';
import { LogIn as LogInData, LogInVariables } from './lib/graphql/mutation/LogIn/__generated__/LogIn';
import { Viewer } from './lib/types';
import { Actions, AddEditUser, AddQuote, CloseActions, Compliance, ConfirmEmail, Contacts, Dashboard, Escalations, ForgotPassword, GenerateAdvancedReport, GenerateReport, Login, MarketingImages, MyAccount, Quotes, SiteDashboard, Sites, Users } from './sections';
import { ContactDashboard } from './sections/ContactDashboard';
import { Help } from './sections/Help';
import { AppStyles } from './styles';
import { NewPortalNotice } from './components';

const initialViewer: Viewer = {
  id: null,
  firstName: null,
  lastName: null,
  userRole: null,
  token: null,
  emailConfirmed: null,
  didRequest: false,
};

function App() {
  const [viewer, setViewer] = useState<Viewer>(initialViewer);
  const [logIn, { error }] = useMutation<LogInData, LogInVariables>(LOG_IN, {
    onCompleted: data => {
      if (data && data.logIn) {
        setViewer(data.logIn);
        if (data.logIn.token) {
          sessionStorage.setItem('token', data.logIn.token);
        } else {
          sessionStorage.removeItem('token');
        }
      } else {
        setViewer(initialViewer);
      }
    },
  });
  const logInRef = useRef(logIn);

  useEffect(() => {
    logInRef.current();
  }, [logInRef]);

  const styles = AppStyles();

  if (!viewer.didRequest && !error) {
    return (
      <LoadingPage />
    );
  }

  if (!viewer || !viewer.id) {
    return (
      <Layout className={styles.app}>
        <NewPortalNotice />
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/:token" element={<ForgotPassword />} />
          <Route path="*" element={<Login setViewer={setViewer} />} />
        </Routes>
      </Layout>
    )
  }

  if (viewer && !viewer.emailConfirmed) {
    return (
      <Layout className={styles.app}>
        <NewPortalNotice />
        <ConfirmEmail setViewer={setViewer} viewer={viewer} />
      </Layout>
    )
  }

  const adminRoutes = viewer?.userRole?.includes("ADMIN") ? (
    <>
      <Route path='/users' element={<Users viewer={viewer} />} />
      <Route path='/users/add' element={<AddEditUser viewer={viewer} />} />
      <Route path='/users/edit/:userId' element={<AddEditUser viewer={viewer} />} />
      <Route path='/quotes/add' element={<AddQuote />} />
      <Route path='/contacts' element={<Contacts />} />
      <Route path='/close-actions' element={<CloseActions />} />
      <Route path='/marketing-images' element={<MarketingImages />} />
      <Route path='/pending-escalations' element={<Escalations />} />
      <Route path='/generate-advanced-report' element={<GenerateAdvancedReport />} />
    </>
  ) : null

  return (
    <Layout className={styles.app}>
      <NewPortalNotice />
      <Affix offsetTop={0}>
        <AppHeader viewer={viewer} setViewer={setViewer} />
      </Affix>
      <Layout>
        <AppSidebar viewer={viewer} />
        <Routes>
          <Route path='/' element={<Dashboard viewer={viewer} />} />
          <Route path="/contact/:contactId/*" element={<ContactDashboard viewer={viewer} />} />
          <Route path='/sites' element={<Sites viewer={viewer} />} />
          <Route path="/site/:siteId/*" element={<SiteDashboard viewer={viewer} />} />
          <Route path='/actions' element={<Actions viewer={viewer} />} />
          <Route path='/generate-report' element={<GenerateReport />} />
          <Route path='/quotes' element={<Quotes viewer={viewer} />} />
          <Route path='/my-account' element={<MyAccount userId={viewer.id} />} />
          <Route path='/compliance' element={<Compliance viewer={viewer} />} />
          <Route path='/help' element={<Help />} />
          {adminRoutes}
          <Route path="*" element={<p>404 Not Found</p>} />
          <Route />
        </Routes>
      </Layout>
    </Layout>
  );
}

export default App;
