import { Col, Row, Typography } from "antd";
import React from "react";
import { CircleRating } from "../../../../../../../../lib/components";
import { Rating } from "../../../../../../../../lib/graphql/globalTypes";
import { getRiskRatingColor, sentenceCase } from "../../../../../../../../lib/utils";
import { spacing } from "../../../../../../../../styles";
import { previousRatingStyles } from "./styles";

interface Props {
  riskRating: Rating
}

const {Title, Text} = Typography

export const PreviousRating: React.FC<Props> = ({riskRating}) => {
  const styles = previousRatingStyles();

  return (
    <Row>
      <Col className={styles.container}>
        <Title level={3}>Previous Rating:</Title>
        <Text style={{fontWeight: "600", color: getRiskRatingColor(riskRating)}}>{sentenceCase(riskRating)}</Text>
      </Col>
      <Col style={{marginLeft: spacing.medium}}>
        <CircleRating title={riskRating} />
      </Col>
    </Row>
  )
}