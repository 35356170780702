import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Image, Layout, Typography } from "antd";
import React, { useState } from "react";
import { MARKETING_IMAGES } from "../../lib/graphql/query/MarketingImages";
import { MarketingImages as MarketingImagesData, MarketingImagesVariables } from "../../lib/graphql/query/MarketingImages/__generated__/MarketingImages";
import { spacing } from "../../styles";
import { marketingImagesStyles } from "./styles";

const { Content } = Layout;
const { Title } = Typography;

export const MarketingImages: React.FC = () => {
  const styles = marketingImagesStyles();
  const [page, setPage] = useState(1);
  const [limit] = useState(100)

  const { data } = useQuery<MarketingImagesData, MarketingImagesVariables>(MARKETING_IMAGES, {
    variables: {
      page,
      limit
    }
  })

  return (
    <Content className={styles.contianer}>
      <Card>
        <Title >Marketing Images</Title>
        <Image.PreviewGroup>
          {
            data?.marketingImages.result?.map(image => <Image style={{ padding: spacing.xSmall }} width={300} src={image.url} />)
          }
        </Image.PreviewGroup>
        <Button style={{ float: "right" }} type='primary' onClick={() => { setPage(page + 1); window.scrollTo(0, 0) }}>Next Page</Button>
        {page > 1 && <Button type='primary' onClick={() => { setPage(page - 1); window.scrollTo(0, 0) }}>Previous Page</Button>}
      </Card>
    </Content>
  )
}