import { gql } from 'apollo-boost';

export const SITE_PLANS = gql`
  query SitePlans($id: String!, $limit: Int!, $page: Int!) {
    sitePlans(id: $id, limit: $limit, page: $page) {
      total
      result {
        id
        fileName
        size
        type
        jobReference
        createdAt
      }
    }
  }
`;