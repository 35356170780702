import { createUseStyles } from 'react-jss';
import { base, colors, queries, spacing } from '../../../../styles';

export const sitesPerCategoryStyles = createUseStyles({
  container: {
    marginTop: spacing.small,
    paddingBottom: 0,
    [queries.l]: {
      display: "none"
    }
  },
  icon: {
    fill: colors.white,
    maxWidth: base(3),
    maxHeight: base(3)
  }
})