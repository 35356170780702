import { createUseStyles } from 'react-jss'
import { base, baselinePX, htmlFontSize } from './base'
import { colors } from './colors'
import './defaults/index.css'
import { queries } from './queries'
import { body, h1, h2, h3, h4, h5 } from './type'

export const AppStyles = createUseStyles({
  '@global': {
    ' @import':
			'url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins:wght@400;600&display=swap")',
    'html, body, #root': {
      margin: 0,
      '-webkit-font-smoothing': 'antialiased',
      height: '100%',
    },
    html: {
      fontSize: htmlFontSize,
      colors: colors.black,
      lineHeight: `${baselinePX}px`,
      backgroundColor: colors.antique,
      [queries.m]: {
        fontSize: htmlFontSize * 0.75,
      },
    },
    h1: h1,
    h2: h2,
    h3: h3,
    h4: h4,
    h5: h5,
    p: {
      ...body
    },
    span: {
      ...body
    },
    ol: {
      padding: `0 0 0 ${base()}`,
      margin: `0 0 ${base()} 0`,
    },
    ul: {
      padding: `0 0 0 ${base()}`,
      margin: `0 0 ${base()} 0`,
    },
    li: {
      ...body,
    },
    main: {
      paddingLeft: base(2),
      paddingRight: base(2),
      paddingBottom: base(2),
      // background: colors.antique
      [queries.s]: {
        paddingLeft: base(0.5),
      paddingRight: base(0.5),
      paddingBottom: base(0.5),
      }
    },
    ".ant-btn": {
      height: "auto"
    },
    ".ant-btn-primary span": {
      color: colors.white
    },
    ".ant-btn-link span": {
      color: colors.green,
      fontSize: 16
    },
    ".ant-form-item .ant-row": {
      display: "block !important"
    },
    "span.ant-table-column-title": {
      color: colors.black,
      fontSize: "14px",
      fontFamily: 'Open Sans, system-ui, sans-serif',
      fontWeight: "600",
    }
  },
  app: {
    minHeight: '100%',
  },
})