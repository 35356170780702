import { gql } from "apollo-boost";

export const ESCALATE_SITE = gql`
  mutation EscalateSite($id: String!, $isEscalated: Boolean!) {
    escalateSite(id: $id, isEscalated: $isEscalated) {
      id
    }
  }
`

export const ADD_SITE_FILE = gql`
  mutation AddSiteFile($input: AddSiteFileInput!) {
    addSiteFile(input: $input) {
      id
    }
  }
`

export const SITE_FILE = gql`
  mutation SiteFile($id: String!) {
    siteFile(id: $id) {
      file
      fileName
      type
    }
  }
`;

export const DELETE_SITE_FILE = gql`
  mutation DeleteSITEFile($id: String!) {
    deleteSiteFile(id: $id)
  }
`;

export const UPDATE_SITE_RATING = gql`
  mutation SetSiteRiskRating($id: String!, $riskRating: String!) {
    setSiteRiskRating(id: $id, riskRating: $riskRating) {
      id
    }
  }
`;

export const TOGGLE_AUTO_CLOSE_SITE_ACTIONS = gql`
  mutation ToggleAutoCloseSiteActions($siteId: String!, $autoClose: Boolean!) {
    toggleAutoCloseSiteActions(siteId: $siteId, autoClose: $autoClose)
  }
`;