import { createUseStyles } from "react-jss"

export const siteDashboardStyles = createUseStyles({
  container: {
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  header: {
    fontWeight: "600",
    textTransform: "uppercase"
  }
})