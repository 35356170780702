import { gql } from "apollo-boost";

export const JOB_CARD = gql`
  mutation JobCard($id: String!) {
    jobCard(id: $id)
  }
`

export const DELETE_JOB = gql`
  mutation DeleteJob($id: String!) {
    deleteJob(id: $id)
  }
`;