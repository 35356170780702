import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const apiUri = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API;

const client = new ApolloClient({
	uri: `${apiUri}/api`,
	credentials: 'include',
	request: async operation => {
		const token = sessionStorage.getItem('token');
		operation.setContext({
			headers: {
				'X-CSRF-TOKEN': token || '',
			},
		});
	},
});

ReactDOM.render(
	<ApolloProvider client={client} >
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</ApolloProvider>,
  document.getElementById('root')
);