import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Col, Form, Input, Layout, Row, Switch, Typography } from "antd";
import { UPDATE_USER } from "../../lib/graphql/mutation/User";
import { UpdateUser, UpdateUserVariables } from "../../lib/graphql/mutation/User/__generated__/UpdateUser";
import { USER } from "../../lib/graphql/query/Users";
import { User as UserData, UserVariables } from "../../lib/graphql/query/Users/__generated__/User";
import { displayErrorMessage, displaySuccessNotification } from "../../lib/utils";
import { myAccountStyles } from "./styles";

interface Props {
  userId: string
}

const {Content} = Layout;
const {Title} = Typography;
const {Item} = Form;

export const MyAccount: React.FC<Props> = ({userId}) => {
  const styles = myAccountStyles();
  const {data: userData, refetch: userRefetch} = useQuery<UserData, UserVariables>(USER, {
    variables: {
      id: userId || "0"
    }
  });
  const [updateUser, {loading}] = useMutation<UpdateUser, UpdateUserVariables>(UPDATE_USER, {
    onCompleted: () => {
      displaySuccessNotification("User updated successfully")
      userRefetch();
    }
  })

  const handleSubmit = (input: any) => {
    if(input.password === input.confirmPassword) {
      updateUser({
        variables: {
          input: {
            ...input,
            id: userId
          }
        }
      });
    }else {
      displayErrorMessage("Passwords do not match, please try again")
    }
      
  }

  if(!userData || !userData.user) {
    if(userId) {
      return null 
    }
  }

  return (
    <Content className={styles.container}>
      <Row>
        <Col md={12} xs={24}>
          <Card>
            <Title>My Account</Title>
            <Form layout='vertical' onFinish={handleSubmit} initialValues={userData?.user || {receiveEmailNotifications: true}}>
            <Row gutter={24}>
                  <Col md={12} xs={24}>
                    <Item label="First Name" name="firstName" rules={[{required: true, message: "Please input a first name"}]}>
                      <Input />
                    </Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Item label="Last Name" name="lastName" rules={[{required: true, message: "Please input a last name"}]}>
                      <Input />
                    </Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col md={18} xs={24}>
                  <Item label="Email Address" name="email" rules={[{required: true, message: 'Your email is required', type: 'email'}]}>
                  <Input placeholder="john.doe@example.com" />
                </Item>
                  </Col>
                  <Col md={6} xs={24}>
                  <Item label="Receive email notifications" name="receiveEmailNotifications" initialValue={userData ? Boolean(userData?.user.receiveEmailNotifications) : true}>
                  <Switch checked={userData ? Boolean(userData?.user.receiveEmailNotifications) : true} />
                  </Item>
                  </Col>
                </Row>
                <Item label="Phone Number" name="phoneNumber">
                  <Input addonBefore={"+44"} />
                </Item>
                <Row gutter={24}>
                <Col md={12} xs={24}>
                    <Item label="Password" name="password" rules={[{
                      pattern: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,128}$/gm,
                      message: 'Password requires a minimum eight characters, at least one uppercase, one lowercase and one number',
                    }]}>
                      <Input.Password placeholder="**********"  />
                    </Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <Item label="Confirm Password" name="confirmPassword">
                      <Input.Password placeholder="**********" />
                    </Item>
                  </Col>
                </Row>
                
                <Item>
                  <Button type="primary" htmlType="submit" loading={loading} >Update</Button>
                </Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Content>
  )
}