import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Form, Input, Layout, Typography } from "antd";
import { UPDATE_PASSWORD } from "../../lib/graphql/mutation/User";
import { UpdatePassword, UpdatePasswordVariables } from "../../lib/graphql/mutation/User/__generated__/UpdatePassword";
import { Viewer } from "../../lib/types";
import { displayErrorMessage, displaySuccessNotification } from "../../lib/utils";
import { base } from "../../styles";
import { ReactComponent as Logo } from './../../assets/logo.svg';
import { confirmEmailStyles } from "./styles";

interface Props {
  setViewer: (viewer: Viewer) => void,
  viewer: Viewer 
}

const {Content} = Layout;
const {Title} = Typography;
const {Item} = Form;

export const ConfirmEmail: React.FC<Props> = ({viewer, setViewer}) => {
  const styles = confirmEmailStyles();
  const [updatePassword] = useMutation<UpdatePassword, UpdatePasswordVariables>(UPDATE_PASSWORD, {
    onCompleted: () => {
      displaySuccessNotification("Successfully activated account");
      setViewer({
        ...viewer,
        emailConfirmed: true
      })
    },
    onError: () => {
      displayErrorMessage("Something went wrong. please try again later")
    }
  })

  const submitForm = (input: any) => {
    if(input.password !== input.confirmPassword) {
      displayErrorMessage("Passwords do not match.")
    }
    updatePassword({
      variables: {
        input
      }
    })
  }

  return (
    <Content className={styles.container}>
      <Card>
        <div className={styles.logoContainer}>
          <Logo width={base(10)} className={styles.logo} />
        </div>
        <Title style={{textAlign: "center"}}>Welcome to My Contego</Title>
        <p>One last thing, please set a password to access this service in the future</p>
        <Form layout="vertical" onFinish={submitForm}>
          <Item label="Password" name="password" rules={[{required: true, message: 'Your password is required'}, {
						pattern: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,128}$/gm,
						message: 'Password requires a minimum eight characters, at least one uppercase, one lowercase and one number',
					}]}>
            <Input type="password" />
          </Item>
          <Item label="Confirm Password" name="confirmPassword" rules={[{required: true, message: 'please confirm your password'}]}>
            <Input type="password" />
          </Item>
          <Item name="submit">
            <Button type="primary" htmlType="submit">Submit</Button>
          </Item>
        </Form>
      </Card>
    </Content>
  )
}