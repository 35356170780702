import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Col, Form, Input, InputNumber, Layout, Row, Select, Typography, Upload } from "antd";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { ADD_QUOTE } from "../../lib/graphql/mutation/Quote";
import { AddQuote as AddQuoteData, AddQuoteVariables } from "../../lib/graphql/mutation/Quote/__generated__/AddQuote";
import { SITES } from "../../lib/graphql/query/Site";
import { Sites, SitesVariables } from "../../lib/graphql/query/Site/__generated__/Sites";
import { displayErrorMessage, displaySuccessNotification } from "../../lib/utils";
import { addQuoteStyles } from "./styles";

const {Content} = Layout;
const {Title} = Typography;
const {Item} = Form;
const {Option} = Select;

export const AddQuote: React.FC = () => {
  const [fileLoading, setFileLoading] = useState(false);
  const [fileBase64Value, setFileBase64Value] = useState<string | null>(null);

  const [lookup, setLookup] = useState("")
  const {data: siteData, loading} = useQuery<Sites, SitesVariables>(SITES, {
    variables: {
      limit: 50,
      page: 1,
      lookup,
      sort: {key: "name", order: "asc"}
    }
  })

  const [addQuote, {data: uploadData}] = useMutation<AddQuoteData, AddQuoteVariables>(ADD_QUOTE, {
    onCompleted: (data) => {
      if(data && data.addQuote) {
        displaySuccessNotification("Quote successfully added")
      }
    },
    onError: (error) => {
      if(error) {
        displayErrorMessage("Something went wrong, please try again later.")
      }
    }
  })

  const styles = addQuoteStyles()

  const handleSiteLookup = (search: any) => {
    setLookup(search)
  }

  const handleFileUpload = (info: any) => {
		const {file} = info;

		if (file.status === 'uploading') {
			setFileLoading(true);
			return;
		}

		if (file.status === 'done' && file.originFileObj) {
			getBase64Value(file.originFileObj, fileBase64Value => {
				setFileBase64Value(fileBase64Value);
				setFileLoading(false);
			});
		}
	};

	const dummyRequest = (option: any) => {
		setTimeout(() => {
			option.onSuccess('ok');
		}, 0);
	};

  const handleFormSubmit = (input: any) => {
    addQuote({
      variables: {
        input: {
          ...input,
          file: fileBase64Value
        }
      }
    })
  }

  if(uploadData) {
    return <Navigate to="/quotes" />
  } 

  return (
    <Content className={styles.container}>
      <Row>
        <Col span={12}>
          <Card>
            <Title>Add A Quote</Title>
            <Form layout="vertical" onFinish={handleFormSubmit}>
              
              <Row gutter={24}>
              <Col span={20}>
                <Item name="reference" label="Reference" rules={[{required: true, message: "Reference is required"}]}>
                  <Input />
                </Item>
                </Col>
                <Col span={4}>
                  <Item name="price" label="Total Price">
                    <InputNumber prefix="£ " />
                  </Item>
                </Col>
              </Row>
              <Item name="siteId" label="Site" rules={[{required: true, message: "Site is required"}]}>
                <Select
                  showSearch
                  placeholder="Select a site"
                  onSearch={handleSiteLookup}
                  filterOption={false}
                >
                  {
                    !loading && siteData?.sites.result.map((site: any) => {
                      return <Option key={site.id} value={site.id}>{site.name}</Option>
                    })
                  }
                </Select>
              </Item>
              <Item label="File" name="file" rules={[{required: true, message: "Quote is required"}]}>
                <Upload
                  name="file"
                  showUploadList={false}
                  customRequest={dummyRequest}
                  beforeUpload={beforeFileUpload}
                  onChange={handleFileUpload}
                >
                  {fileBase64Value ? (
                    <p>File uploaded!</p>
                  ) : (
                    <>
                      {fileLoading && <p>File uploading</p>}
                      <Button type="primary">Upload Quote</Button>
                    </>
                  )}
                </Upload>
              </Item>
              <Item name="note" label="Note">
                <Input.TextArea />
              </Item>
              <Item>
                <Button type="primary" htmlType="submit">Submit</Button>
              </Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Content>
  )
}

const beforeFileUpload = (file: File) => {
	const fileIsValidFile = file.type === 'application/pdf'
  || file.type === "application/vnd.ms-excel"
  || file.type === "image/png"
  || file.type === "image/jpg"
  || file.type === "application/msword"
  || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  || file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  || file.type === "application/vnd.ms-powerpoint"
  || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
	const fileIsValidSize = file.size / 1024 / 1024 < 1;

	if (!fileIsValidFile) {
		displayErrorMessage('You\'re only able to upload valid JPG or PNG files!');
		return false;
	}

	if (!fileIsValidSize) {
		displayErrorMessage(
			'You\'re only able to upload valid image files of under 1MB in size!',
		);
		return false;
	}

	return fileIsValidFile && fileIsValidSize;
};

const getBase64Value = (
	img: File | Blob,
	// eslint-disable-next-line no-unused-vars
	callback: (imageBase64Value: string) => void,
) => {
	const reader = new FileReader();
	reader.readAsDataURL(img);
	reader.onload = () => {
		callback(reader.result as string);
	};
};