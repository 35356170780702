import { createUseStyles } from "react-jss";
import { colors, h1, spacing } from "../../../styles";

export const AppSidebarStyles = createUseStyles({
  container: {
    background: colors.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .ant-layout-sider-trigger": {
      background: colors.green
    }
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center"
  },
  logo: {
    fill: colors.green, 
    paddingLeft: spacing.xSmall, 
    paddingRight: spacing.xSmall,
    position: "absolute",
    bottom: spacing.xSmall
  },
  syncStatusContainer: {
    marginTop: spacing.medium,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  syncStatusIcon: {
    fontSize: h1.fontSize,
    color: colors.green
  }
})