import { createUseStyles } from 'react-jss';
import { colors, spacing } from '../../styles';

export const escalationStyles = createUseStyles({
  container: {
    marginTop: spacing.medium
  },
  table: {

  },
  escalateLink: {
    '& *': {
      color: colors.red
    }
  }
})