import { gql } from 'apollo-boost';

export const SITES = gql`
  query Sites($limit: Int!, $page: Int!, $lookup: String, $sort: SortField, $filter: FilterField) {
    sites(limit: $limit, page: $page, lookup: $lookup, sort: $sort, filter: $filter) {
      total,
      result {
        id,
        contactId
        name,
        street,
        town,
        postcode,
        parentContactId
        inEscalation,
        _count {
          actions
        },
        ratings {
          id
          riskRating
          pestRisk
        }
        createdAt,
        updatedAt
      }
    }
  }
`;

export const SITE = gql`
  query Site($id: String!) {
    site(id: $id) {
      id,
      name,
      street,
      town,
      postcode,
      nextVisit,
      nextTechnician,
      previousVisit,
      previousTechnician,
      inEscalation,
      autoCloseActions,
      _count {
          actions
        },
        ratings {
          id
          riskRating
          pestRisk
        }
      createdAt,
      updatedAt
    }
  }
`;


export const SITE_TRENDS = gql`
  query SiteTrends($id: String!) {
    siteTrends(id: $id) {
      id
      riskRating
      pestRisk
      date
    }
  }
`;

export const SITE_FILES = gql`
  query SiteFiles($id: String!, $limit: Int!, $page: Int!) {
    siteFiles(id: $id, limit: $limit, page: $page) {
      total
      result {
        id
        fileName
        size
        type
        createdAt
      }
    }
  }
`;