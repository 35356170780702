import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Layout, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UpdateQuote } from "../../lib/components";
import { QUOTES } from "../../lib/graphql/query/Quote";
import { Quotes as QuotesData, QuotesVariables } from "../../lib/graphql/query/Quote/__generated__/Quotes";
import { Viewer } from "../../lib/types";
import { sentenceCase } from "../../lib/utils";
import { base, colors } from "../../styles";
import { quotesStyles } from "./styles";

interface Props {
  viewer: Viewer
}

const {Content} = Layout
const {Title} = Typography;

export const Quotes: React.FC<Props> = ({viewer}) => {
  const [selectedQuote, setSelectedQuote] = useState<null | string>(null)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState({key: "createdAt", order: "desc"})
  const {data, loading, refetch} = useQuery<QuotesData, QuotesVariables>(QUOTES, {
    variables: {
      limit,
      page,
      sort
    }
  })
  const styles = quotesStyles();

  useEffect(() => {
    refetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuote])

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: base(7.5),
      sorter: true,
      render: (date: Date) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      title: "Site Name",
      dataIndex: "site",
      key: "site",
      width: base(14),
      render: (site: any) => site.name
    },
    {
      title: "Quote Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: any) => `£${price}`
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => status === "ACCEPTED" ? <Tag color={colors.green}>{sentenceCase(status)}</Tag> : <Tag>{status}</Tag>
    },
    {
      title: "Update",
      key: "update",
      render: (item: any) => <Button type="link" onClick={() => setSelectedQuote(item.id)}>View</Button>
    }
  ]

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSort({
      key: sorter.columnKey,
      order: sorter.order === "ascend" ? "asc" : "desc"
    })
  }
  

  return (
    <Content className={styles.container}>
      <Card>
        <span className={styles.titleContainer}>
          <Title>Quotes</Title>
          {
            (viewer?.userRole?.includes("ADMIN") || viewer?.userRole?.includes("ACCOUNTMANAGER")) && (
              <Link to="/quotes/add">
                <Button type="primary" className={styles.addQuoteButton}>Add Quote</Button>
              </Link>
            )
          }
        </span>
        <Table 
          className={styles.table}
          dataSource={data?.quotes.result as []} 
          columns={columns} 
          loading={loading}
          pagination={{
						defaultPageSize: limit,
						defaultCurrent: page,
						total: data?.quotes.total,
						onChange: handlePaginationChange,
					}}
          onChange={handleTableChange}
        />
      </Card>
      <UpdateQuote setModalVisible={setSelectedQuote} quoteId={selectedQuote} />
    </Content>
  )
}