import { base, htmlFontSize } from './base';
import { colors } from './colors';

const heading = {
	fontWeight: 'semibold',
	colors: colors.black,
};

const h1 = {
	...heading,
	fontSize: base(2.1),
	lineHeight: base(3.4),
	colors: colors.black,
};

const h2 = {
	...heading,
	fontSize: base(2.1),
	lineHeight: base(3.4),
};

const h3 = {
	...heading,
	fontSize: base(1.2),
	lineHeight: base(1.2),
};

const h4 = {
	...heading,
	fontSize: base(1),
	lineHeight: base(1.5),
};

const h5 = {
	...heading,
	fontSize: base(1),
	lineHeight: base(1.5),
};

const body = {
	fontSize: htmlFontSize,
	lineHeight: base(1.3),
	fontFamily: 'Open Sans, system-ui, sans-serif',
	fontWeight: 400,
	color: colors.black
};


export {
	h1,
	h2,
	h3,
	h4,
	h5,
	body,
};

