import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Modal, Typography, Upload } from "antd";
import React, { useState } from "react";
import { displayErrorMessage, displaySuccessNotification } from "../../utils";
import { AddSitePlan as AddSitePlanData, AddSitePlanVariables } from "../../graphql/mutation/SitePlans/__generated__/AddSitePlan";
import { ADD_SITE_PLAN } from "../../graphql/mutation/SitePlans";

interface Props {
  setModalVisible: (modalVisible: boolean) => void
  modalVisible?: boolean
  siteId: string
}

const {Item} = Form;
const {Title} = Typography;

export const AddSitePlan: React.FC<Props> = ({setModalVisible, modalVisible, siteId}) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [fileBase64Value, setFileBase64Value] = useState<string | null>(null);

  const [addSiteFile] = useMutation<AddSitePlanData, AddSitePlanVariables>(ADD_SITE_PLAN, {
    onCompleted: data => {
      if(data && data.addSitePlan) {
        displaySuccessNotification("File Successfully added");
      }
    },
    onError: err => {
      if(err) {
        displayErrorMessage("Something went wrong, please try again later.");
      }
    }
  })

  const handleFileUpload = (info: any) => {
		const {file} = info;

		if (file.status === 'uploading') {
			setFileLoading(true);
			return;
		}

		if (file.status === 'done' && file.originFileObj) {
			getBase64Value(file.originFileObj, fileBase64Value => {
				setFileBase64Value(fileBase64Value);
				setFileLoading(false);
			});
		}
	};

	const dummyRequest = (option: any) => {
		setTimeout(() => {
			option.onSuccess('ok');
		}, 0);
	};

  const handleAddSiteFile = (input: any) => {
    addSiteFile({
      variables: {
        input: {
          id: siteId,
          file: fileBase64Value as string,
          fileName: input.file.file.name,
          fileSize: input.file.file.size,
          type: input.file.file.type
        }
      }
    })
    setModalVisible(false)
  }

  return (
    <Modal
      visible={Boolean(modalVisible)}
      onCancel={() => setModalVisible(false)}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <Title level={2}>Add File</Title>
      <Form layout="vertical" onFinish={handleAddSiteFile}>
        <Item label="File" name="file" rules={[{required: true, message: "File is required"}]}>
                <Upload
                  name="file"
                  showUploadList={false}
                  customRequest={dummyRequest}
                  beforeUpload={beforeFileUpload}
                  onChange={handleFileUpload}
                >
                  {fileBase64Value ? (
                    <p>File uploaded!</p>
                  ) : (
                    <>
                      {fileLoading && <p>File uploading</p>}
                      <Button type="primary">Upload File</Button>
                    </>
                  )}
                </Upload>
              </Item>
        <Item>
            <Button type="primary" htmlType="submit">Submit</Button>
        </Item>
      </Form>
    </Modal>    
  )
}

const beforeFileUpload = (file: File) => {
	const fileIsValidFile = (
    file.type === 'application/pdf'
    || file.type === "image/png"
    || file.type === "image/jpg"
  );
	const fileIsValidSize = file.size / 1024 / 1024 < 5;

	if (!fileIsValidFile) {
		displayErrorMessage('You\'re only able to upload valid pdf image files!');
		return false;
	}

	if (!fileIsValidSize) {
		displayErrorMessage(
			'You\'re only able to upload valid files of under 5MB in size!',
		);
		return false;
	}

	return fileIsValidFile && fileIsValidSize;
};

const getBase64Value = (
	img: File | Blob,
	// eslint-disable-next-line no-unused-vars
	callback: (imageBase64Value: string) => void,
) => {
	const reader = new FileReader();
	reader.readAsDataURL(img);
	reader.onload = () => {
		callback(reader.result as string);
	};
};