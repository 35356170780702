import { gql } from "apollo-boost";

export const SITE_ACTIONS = gql`
  query SiteActions($id: String!, $limit: Int!, $page: Int!, $sort: SortField, $filter: String) {
    siteActions(id: $id, limit: $limit, page: $page, sort: $sort, filter: $filter) {
      total
      result {
        id
        type
        status
        description
        assignedTo
        details
        riskRating
        closeNotes
        date
      }
    }
  }
`

export const SITE_ACTIONS_SUMMARY = gql`
  query SiteActionsSummary($id: String!) {
    siteActionsSummary(id: $id) {
      veryHighRisk
      highRisk
      mediumRisk
      lowRisk
      customerActions
      contegoActions
      buildingCondition
      pestActivity
      hygiene
      proofing
      observational
      service
    }
  }
`

export const ACTIONS = gql`
  query Actions($limit: Int!, $page: Int!, $sort: SortField, $filter: FilterField) {
    actions(limit: $limit, page: $page, sort: $sort, filter: $filter) {
      total
      result {
        id
        type
        status
        description
        assignedTo
        details
        riskRating
        closeNotes
        date
        site {
          id
          name
          street
          postcode
        }
      }
    }
  }
`

export const ACTION = gql`
  query Action($id: String!) {
    action(id: $id) {
      id
      type
      status
      description
      assignedTo
      details
      riskRating
      closeNotes
      jobId
      date
      site {
        id
        name
        street
        postcode
      }
      images {
        url
      }
    }
  }
`