import { DownCircleFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, Dropdown, Layout, Menu, Typography } from 'antd';
import React from 'react';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { base, colors } from '../../../styles';
import { LOG_OUT } from '../../graphql/mutation/LogOut';
import { LogOut } from '../../graphql/mutation/LogOut/__generated__/LogOut';
import { Viewer } from '../../types';
import { displaySuccessNotification, sentenceCase } from '../../utils';
import { AppHeaderStyles } from './styles';

interface Props {
  viewer: Viewer
  setViewer: (viewer: Viewer) => void
}

const {Header } = Layout
const {Text} = Typography


export const AppHeader: React.FC<Props> = ({viewer, setViewer}) => {

  const [logout] = useMutation<LogOut>(LOG_OUT, {
    onCompleted: data => {
      if(data.logOut) {
        setViewer({
          id: null,
          firstName: null,
          lastName: null,
          userRole: null,
          token: null,
          emailConfirmed: null,
          didRequest: true,
        })
        sessionStorage.clear()
        displaySuccessNotification("You've successfully logged out.")
      }
    }
  });

  const styles = AppHeaderStyles();
  return (
    <Header className={styles.container}>
      <Logo height={"75%"} className={styles.logo} />
      <div>
        <Text className={styles.text}>Welcome, {viewer.firstName} {viewer.lastName} ({viewer.userRole && sentenceCase(viewer.userRole)})</Text>
        <Dropdown overlay={
          <Menu>
            <Menu.Item>
              <Button type="link" onClick={() => {logout()}}>Logout</Button>
            </Menu.Item>
          </Menu>
        }>
          <DownCircleFilled style={{color: colors.white, cursor: "pointer", fontSize: base(1.25)}} />
        </Dropdown>
      </div>
    </Header>
  )
}