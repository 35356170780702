import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Card, Layout, Popconfirm, Popover, Space, Table, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ACCEPT_PENDING_ESCALATION } from '../../lib/graphql/mutation/Escalation';
import { AcceptPendingEscalation, AcceptPendingEscalationVariables } from '../../lib/graphql/mutation/Escalation/__generated__/AcceptPendingEscalation';
import { PENDING_ESCALATION } from '../../lib/graphql/query/Escalation';
import { PendingEscalations, PendingEscalationsVariables } from '../../lib/graphql/query/Escalation/__generated__/PendingEscalations';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { base, colors } from '../../styles';
import { escalationStyles } from './styles';

const { Content } = Layout;
const { Title, Text } = Typography;

const displayRiskPill = (risk: string) => {
  switch (risk) {
    case "VERYHIGH":
      return <Tag color={colors.black}>Very High</Tag>
    case "HIGH":
      return <Tag color={colors.red}>High</Tag>
    case "MEDIUM":
      return <Tag color={colors.orange}>Medium</Tag>
    case "LOW":
      return <Tag color={colors.green}>Low</Tag>
  }
}

export const Escalations: React.FC = () => {
  const styles = escalationStyles();
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const { data, loading, refetch } = useQuery<PendingEscalations, PendingEscalationsVariables>(PENDING_ESCALATION, {
    variables: {
      page,
      limit,
    }
  });

  const [acceptEscalation] = useMutation<AcceptPendingEscalation, AcceptPendingEscalationVariables>(ACCEPT_PENDING_ESCALATION, {
    onCompleted: data => {
      if (data && data.acceptPendingEscalation) {
        displaySuccessNotification('Escalation accepted');
        refetch();
      }
    },
    onError: error => {
      if (error) {
        displayErrorMessage('Error accepting escalation');
      }
    }
  })

  const handleEscalateSite = (id: string) => {
    acceptEscalation({
      variables: {
        id,
      }
    })
  }

  const columns = [
    {
      title: "Date of proposed escalation",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record: any) => <>{moment(record).format('YYYY-MM-DD')}</>,
      width: base(10),
    },
    {
      title: "Site Name",
      dataIndex: "site",
      key: "site",
      render: (record: any) => <>{record.name}</>,
    },
    {
      title: "Risk Rating",
      dataIndex: "site",
      key: "ratings",
      render: (site: any) => (<>{site.ratings[0] ? displayRiskPill(site.ratings[0].riskRating) : ""}</>)
    },
    {
      title: "Pest Risk",
      dataIndex: "site",
      key: "pests",
      render: (site: any) => (<>{site.ratings[0] ? site.ratings[0].pestRisk.length ? site.ratings[0].pestRisk : "" : ""}</>)
    },
    {
      title: "Days at risk",
      dataIndex: "site",
      key: "daysAtRisk",
      render: (record: any) => <>{record.ratings[0] ? moment.duration(moment().diff(record.ratings[0].date)).asDays().toFixed() : ""}</>,
      width: base(10),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: any) => (
        <Space>
          <Popconfirm
            title="Are you sure you want to escalate this site?"
            okText="Confirm"
            onConfirm={() => {
              handleEscalateSite(record.id);
            }}
          >
            <Button type='link'><Text type="danger">Escalate</Text></Button>
          </Popconfirm>
          <Link to={`/site/${record.site.id}`}>
            <Button type='link'>
              View Site
            </Button>
          </Link>
        </Space>
      )
    }
  ]

  const handlePaginationChange = (page: number, size: number) => {
    setPage(page);
    setLimit(size);
  };

  return (
    <Content className={styles.container}>
      <Card>
        <Title>Pending Site Escalations</Title>
        <Table
          className={styles.table}
          dataSource={data?.pendingEscalations.result as []}
          columns={columns}
          loading={loading}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.pendingEscalations.total,
            onChange: handlePaginationChange,
          }}
          rowKey="email"
        />
      </Card>
    </Content>
  )
}