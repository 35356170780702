import { useQuery } from '@apollo/react-hooks';
import { Card, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { SITE_JOBS } from '../../../../../../lib/graphql/query/Job';
import { SiteJobs, SiteJobsVariables } from '../../../../../../lib/graphql/query/Job/__generated__/SiteJobs';
import { urgentActionsTableStyles } from './styles';

interface Props {
  siteId: string | undefined
}

const columns = [
  {
    title: "Reference",
    dataIndex: "ref",
    key: "ref",
  },
  {
    title: "Start Date",
    dataIndex: "plannedStart",
    key: "plannedStart",
    sorter: true,
    render: (plannedStart: Date) => dayjs(plannedStart).format("DD-MM-YYYY"),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Technician",
    dataIndex: "resourceName",
    key: "resourceName",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
]

const {Title} = Typography;

export const UpcomingJobs: React.FC<Props> = ({siteId}) => {
  const [sort, setSort] = useState({key: "plannedStart", order: "asc"})
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const {data, loading} = useQuery<SiteJobs, SiteJobsVariables>(SITE_JOBS, {
    variables: {
      id: siteId as string,
      limit,
      page,
      sort,
      filterOut: "Completed"
    }
  })

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSort({
      key: sorter.columnKey,
      order: sorter.order === "ascend" ? "asc" : "desc"
    })
  }

  const styles = urgentActionsTableStyles();
  return (
    <Card className={styles.container}>
      <Title level={2}>Upcoming Jobs</Title>
      <Table 
        dataSource={data?.siteJobs.result} 
        columns={columns}
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          total: data?.siteJobs.total,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        loading={loading}
      />
    </Card>
  )
}