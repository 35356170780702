import { gql } from "apollo-boost";

export const UPDATE_ACTION = gql`
  mutation UpdateAction($id: String, $status: String, $closeNotes: String) {
    updateAction(id: $id, status: $status, closeNotes: $closeNotes) {
      id
    }
  }
`

export const CLOSE_ACTIONS = gql`
  mutation CloseActions($input: CloseActionsInput!) {
    closeActions(input: $input)
  }
`