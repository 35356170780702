import { createUseStyles } from 'react-jss';
import { colors, spacing } from '../../../../styles';

export const jobsStyles = createUseStyles({
  container: {
    marginTop: spacing.medium,
    overflowX: "auto"
  },
  deleteButton: {
    '& *': {
      color: `${colors.red} !important`
    }
  }
})