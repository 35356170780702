import { createUseStyles } from 'react-jss';
import { spacing } from '../../styles';

export const helpStyles = createUseStyles({
  container: {
    marginTop: spacing.medium
  },
  spacerContainer: {
    marginTop: spacing.small
  }
})