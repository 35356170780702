import { useQuery } from "@apollo/react-hooks";
import { Card, Row, Typography } from "antd";
import React, { useState } from "react";
import { SITE_TRENDS } from "../../../../../../lib/graphql/query/Site";
import { SiteTrends as SiteTrendsData, SiteTrendsVariables } from "../../../../../../lib/graphql/query/Site/__generated__/SiteTrends";
import { TrendBox } from "./components";
import { siteTrendsStyles } from "./styles";

interface Props {
  siteId: String | undefined
}

const {Title} = Typography;


export const SiteTrends: React.FC<Props> = ({siteId}) => {
  const [trends, setTrends] = useState<any>()
  useQuery<SiteTrendsData, SiteTrendsVariables>(SITE_TRENDS, {
    variables: {
      id: siteId as string
    },
    onCompleted: data => {
      setTrends(data.siteTrends.reverse())
    }
  })
  const styles = siteTrendsStyles();
  

  return (
  <Card className={styles.container}>
    <Title>Overall Site Risk Rating: Trending</Title>
    <Row gutter={24}>
      {
        trends && (
          trends.map((trend: any) => (
            <TrendBox data={trend} />
          ))
        )
      }
    </Row>
  </Card>
  )
}