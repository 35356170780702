import React, { useState } from "react";
import { newPortalNoticeStyles } from "./styles";
import { Button } from "antd";

export const NewPortalNotice: React.FC = () => {
  const [visible, setVisible] = useState(true);
  const styles = newPortalNoticeStyles();

  if (!visible) return null;

  return (
    <div className={styles.container}>
      <Button className={styles.closeButton} onClick={() => {
        setVisible(false);
      }}
        type="ghost"
      >
        Close
      </Button>
      <h2>
        Ready For Our New Portal?
      </h2>
      <p>
        We're excited to announce that we're launching a new portal for our customers.
      </p>
      <Button type="primary"
        onClick={() => window.open('https://synergy.contegoresponse.com')}
      >
        Open Contego Synergy!
      </Button>
    </div>
  )
}