import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Col, DatePicker, Form, Layout, Row, Select, Steps, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { GENERATE_REPORT } from "../../lib/graphql/mutation/Report";
import { GenerateReport as GenerateReportData, GenerateReportVariables } from "../../lib/graphql/mutation/Report/__generated__/GenerateReport";
import { SITES } from "../../lib/graphql/query/Site";
import { Sites, SitesVariables } from "../../lib/graphql/query/Site/__generated__/Sites";
import { generateReportStyles } from "./styles";

const {Content} = Layout
const {Title} = Typography;
const {Step} = Steps
const {Item} = Form;
const {Option} = Select;

export const GenerateReport: React.FC = () => {
  const [lookup, setLookup] = useState('');
  const {data: sites} = useQuery<Sites, SitesVariables>(SITES, {
    variables: {
      page: 1,
      limit: 50,
      lookup,
      sort: {key: "name", order: "desc"}
    }
  })
  const [generateReport] = useMutation<GenerateReportData, GenerateReportVariables>(GENERATE_REPORT, {
    onCompleted: (data) => {
      if(data && data.generateReport) {
        setStep(2)
        let fetchDataModified = `data:application/xlsx;base64,${data.generateReport}`
        let a = document.createElement("a")
        a.href = fetchDataModified
        a.download = `overview-report-${dayjs().format("DD-MM-YYYY")}.xlsx`
        a.click()
      }
    }
  })
  const [step, setStep] = useState(0)
  const styles = generateReportStyles();

  const handleSubmit = (input: any) => {
    setStep(1)
    generateReport({
      variables: {
        input
      }
    })
  }

  const handleContactLookup = (search: any) => {
    setLookup(search)
  }

  return (
    <Content className={styles.container}>
      <Row gutter={24}>
        <Col lg={12} xs={24}>
          <Card>
          <Title>Generate A Report</Title>
            <Steps current={step}>
              <Step title="Start" />
              <Step title="In Progress" subTitle="Please wait" />
              <Step title="Complete"/>
            </Steps><br />
            <Form layout="vertical" onFinish={handleSubmit}>
              <Item name="id" label="Contact" rules={[{required: true, message: "Contact is required"}]}>
                <Select
                  showSearch
                  placeholder="Search for a contact"
                  onSearch={handleContactLookup}
                  filterOption={false}
                  notFoundContent={lookup.length > 2 ? "No contacts found" : "Try searching the first 3 characters of the contact name"}
                >
                  {
                    sites?.sites.result.map((site: any) => {
                      if(site.parentContactId === null) {
                        return <Option key={site.id} value={site.id}>{site.name}</Option>
                      }
                      return null
                    })
                  }
                </Select>
              </Item>
              <Row gutter={24}>
                <Col span={12}>
                  <Item name="startDate" label="Start Date" rules={[{required: true, message: "Start date is required"}]}>
                    <DatePicker />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item name="endDate" label="End Date" rules={[{required: true, message: "End date is required"}]}>
                    <DatePicker />
                  </Item>
                </Col>
              </Row>
              <Item>
                <Button type="primary" htmlType="submit">Generate</Button>
              </Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Content>
  )
}