import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ControlFilled, FileExclamationFilled, FileFilled, HomeFilled, LoadingOutlined, QuestionCircleFilled, SettingOutlined, TagsFilled, ToolFilled,
  UsergroupAddOutlined, UserOutlined, WarningOutlined
} from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Layout, Menu, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { base } from '../../../styles';
import { IS_SYNCING } from '../../graphql/query/IsSyncing';
import { IsSyncing } from '../../graphql/query/IsSyncing/__generated__/IsSyncing';
import { Viewer } from '../../types';
import { AppSidebarStyles } from './styles';

interface Props {
  viewer: Viewer
}

const {Sider} = Layout;
const {Item, SubMenu} = Menu;
const {Text} = Typography;

export const AppSidebar: React.FC<Props> = ({viewer}) => {
  const {data: syncData} = useQuery<IsSyncing>(IS_SYNCING)
  const [currentPage, setCurrentPage] = useState("")
  const styles = AppSidebarStyles();

  const handlePageChange = (e: {key: string}) => {
    setCurrentPage(e.key)
  }

  const adminSubMenu = viewer?.userRole?.includes("ADMIN") ? (
    <SubMenu key="admin" title="Admin" icon={<SettingOutlined />}>
      <Item key="users" icon={<UsergroupAddOutlined />}>
        <Link to="users">
          <Text>Users</Text>
        </Link>
      </Item>
      <Item key="contacts" icon={<UserOutlined />}>
        <Link to="contacts">
          <Text>Contacts</Text>
        </Link>
      </Item>
      <Item key="closeActions" icon={<CloseCircleOutlined />}>
        <Link to="close-actions">
          <Text>Close Actions</Text>
        </Link>
      </Item>
      <Item key="pendingEscalations" icon={<WarningOutlined />}>
        <Link to="pending-escalations">
          <Text>Escalations</Text>
        </Link>
      </Item>
      <Item key="generateAdvancedReports" icon={<FileFilled />}>
        <Link to="generate-advanced-report">
          <Text>Advanced Reporting</Text>
        </Link>
      </Item>
    </SubMenu>
  
  ): null

  return (
    <Sider className={styles.container} collapsible={true}>
      <Menu defaultOpenKeys={["management", "admin"]} mode="inline" selectedKeys={[currentPage]} onClick={handlePageChange}>
        <Item key="overview" icon={<Logo width={base(1)} style={{fill: "#6C7275"}} />}>
          <Link to="/">
            <Text>Overview</Text>
          </Link>
        </Item>
        <SubMenu key="management" title="Management" icon={<ControlFilled />}>
          <Item key="sites" icon={<HomeFilled />}>
            <Link to="/sites">
              <Text>Sites</Text>
            </Link>
          </Item>
          <Item key="actions" icon={<FileExclamationFilled />}>
            <Link to="actions">
              <Text>Actions</Text>
            </Link>
          </Item>
          <Item key="generate-report" icon={<FileFilled />}>
            <Link to="generate-report">
              <Text>Reporting</Text>
            </Link>
          </Item>
          <Item key="quotes" icon={<TagsFilled />}>
            <Link to="quotes">
              <Text>Quotes</Text>
            </Link>
          </Item>
        </SubMenu>
        {adminSubMenu}
        <Item key="compliance" icon={<Logo width={base(1)} style={{fill: "#6C7275"}} />}>
          <Link to="compliance">
            <Text>Compliance</Text>
          </Link>
        </Item>
        <Item key="help" icon={<QuestionCircleFilled />}>
          <Link to="help">
            <Text>Help</Text>
          </Link>
        </Item>
        <Item key="myAccount" icon={<ToolFilled />}>
          <Link to="my-account">
            <Text>My Account</Text>
          </Link>
        </Item>
      </Menu>
      {syncData?.isSyncing ? (
        <div className={styles.syncStatusContainer}>
          <LoadingOutlined className={styles.syncStatusIcon} /> 
          <p>Syncing</p>
        </div>
      ) : (
        <div className={styles.syncStatusContainer}>
        <CheckCircleOutlined className={styles.syncStatusIcon} />
        <p>Synced</p>
        </div>
      )}
    </Sider>
  )
}