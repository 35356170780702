import { message, notification } from 'antd';
import { colors } from '../../styles';
import { Rating } from '../graphql/globalTypes';

type TJoinClassNames = string[];
export const joinClassNames = (classes: TJoinClassNames): string => [...classes].filter(Boolean).join(' ');



export const displaySuccessNotification = (
	message: string,
	description?: string,
) => notification.success({
	message,
	description,
	placement: 'topLeft',
	style: {
		marginTop: 50,
	},
});

export const displayErrorMessage = (error: string) => message.error(error);

export const percentDifferenceCheck = (num1: number, num2: number): string => (((num1 - num2) / (num1 + num2) / 2) * 100).toFixed(2);

export const sentenceCase = (str: string): string => {
  return str.toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase());
}

export const getRiskRatingColor = (riskRating: Rating | null | undefined) => {
	switch(riskRating) {
		case Rating.VERYHIGH:
			return colors.black
		case Rating.HIGH:
			return colors.red
		case Rating.MEDIUM:
			return colors.orange
		case Rating.LOW:
			return colors.lowRating
		default:
			return colors.green
	}
}