import { createUseStyles } from 'react-jss';
import { spacing } from '../../styles';

export const addEditUser = createUseStyles({
  container: {
    marginTop: spacing.medium
  },
  treeContainer: {
    maxHeight: "60vh",
    overflowY: "auto"
  }
})