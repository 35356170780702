import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Layout, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UpdateAction } from "../../lib/components";
import { UserRole } from "../../lib/graphql/globalTypes";
import { ACTIONS } from "../../lib/graphql/query/Action";
import { Actions as ActionsData, ActionsVariables } from "../../lib/graphql/query/Action/__generated__/Actions";
import { Viewer } from "../../lib/types";
import { sentenceCase } from "../../lib/utils";
import { base, colors } from "../../styles";
import { actionsStyles } from "./styles";

interface Props {
  initialLimit?: number
  viewer: Viewer
}

const { Content } = Layout;
const {Title} = Typography;

export const Actions: React.FC<Props> = ({initialLimit = 20, viewer}) => {
  const [selectedAction, setSelectedAction] = useState<null | string>(null)

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(initialLimit);
  const [sort, setSort] = useState({key: "date", order: "desc"})
  const [filter, setFilter] = useState({key: "", value: ""})
  const {data, loading, refetch} = useQuery<ActionsData, ActionsVariables>(ACTIONS, {
    variables: {
      page,
      limit,
      sort,
      filter
    },
  })
  const styles = actionsStyles();

  let [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const riskRating = searchParams.get("riskRating");
    if(riskRating) {
      setFilter({key: "riskRating", value: riskRating?.toUpperCase()})
    }
  }, [searchParams])

  const columns = [
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      width: base(10),
      render: (site: any) => site.name
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: base(7.5),
      sorter: true,
      render: (date: Date) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: base(7.5),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
      sorter: true,
      width: base(7.5),
      render: (assignedTo: string) => sentenceCase(assignedTo)
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        if(status.includes("Completed")) {
          return <Tag color={colors.green}>{status}</Tag>
        }
        return <Tag>{status}</Tag>
      }
    },
    {
      title: "Risk Rating",
      dataIndex: "riskRating",
      key: "riskRating",
      filters: [
        {
          text: 'Very High',
          value: 'VERYHIGH',
        },
        {
          text: 'High',
          value: 'HIGH',
        },
        {
          text: 'Medium',
          value: 'MEDIUM',
        },
        {
          text: 'Low',
          value: 'LOW',
        },
      ],
      filterMultiple: false,
      render: (riskRating: string) => {
        switch(riskRating) {
          case "VERYHIGH":
            return <Tag color={colors.black}>Very High</Tag>
          case "HIGH":
            return <Tag color={colors.red}>High</Tag>
          case "MEDIUM":
            return <Tag color={colors.orange}>Medium</Tag>
          case "LOW":
            return <Tag color={colors.lowRating}>Low</Tag>
          default:
            return null
        }
      }
    },
    {
      title: "Close Notes",
      dataIndex: "closeNotes",
      key: "closeNotes",
    },
    {
      title: "Update",
      key: "update",
      render: (item: any) => (
          (item.assignedTo === "CUSTOMER" || (viewer.userRole === UserRole.ADMIN || viewer.userRole === UserRole.ACCOUNTMANAGER)) && <Button type="link" onClick={() => setSelectedAction(item.id)}>Update</Button>
        )
    }
  ]

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    setSort({
      key: sorter.columnKey,
      order: sorter.order === "ascend" ? "asc" : "desc"
    })
    setFilter(
      {
        key: 'riskRating',
        value: filters.riskRating[0]
      }
    )
  }

  return (
    <Content className={styles.container}>
      <Card>
        <Title>Actions</Title>
        <Table 
          className={styles.table}
          dataSource={data?.actions.result as []} 
          columns={columns} 
          loading={loading}
          pagination={{
						defaultPageSize: limit,
						defaultCurrent: page,
						total: data?.actions.total,
						onChange: handlePaginationChange,
					}}
          onChange={handleTableChange}
        />
      </Card>
      <UpdateAction setModalVisible={setSelectedAction} actionId={selectedAction} refetch={refetch} />
    </Content>
  )
}