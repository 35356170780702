import { Card, Layout, Typography } from "antd";
import React from "react";
import { helpStyles } from "./styles";

const {Content} = Layout;
const {Title, Paragraph} = Typography;

export const Help: React.FC = () => {
  const styles = helpStyles();

  return (
    <Content className={styles.container}>
      <Card>
        <Title>Help</Title>
        <Paragraph>Here are some guides to introduce you to the myContego portal. Please feel free to contact us on the live chat or via <a href='tel:08000287111'>0800 028 7111</a> if you require any further assistance.</Paragraph>
        <div className={styles.spacerContainer}>
          <Title level={2}>Your guide to myContego</Title>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/XcFUyvEARfg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className={styles.spacerContainer}>
          <Title level={2}>Download the myContego guide</Title>
          <a href='/resources/mycontego-guide.pdf' target="_blank">
            <img src="/resources/mycontego-guide-screenshot.png" alt="Screenshot of the my contego guide front cover" />
          </a>
        </div>
      </Card>
    </Content>
  )
}