import { Button, PageHeader, Typography } from 'antd';
import React, { useState } from 'react';
import { RequestQuote, RequestVisit } from '../../../../lib/components';
import { headerStyles } from './styles';

interface Props {
  title?: string
}

export const Header: React.FC<Props> = ({
  title = 'Welcome To My Contego'
}) => {
  const styles = headerStyles();

  const {Text} = Typography;

  const [quoteModalVisible, setQuoteModalVisible] = useState<boolean>(false)
  const [visitModalVisible, setVisitModalVisible] = useState<boolean>(false)
  
  const headerContent = (
    <div className={styles.headerContent}>
      {/* <Button className={styles.quoteButton + " " + styles.infoButton} type='default' onClick={() => setQuoteModalVisible(!quoteModalVisible)}>Generate Report</Button> */}
      <Button className={styles.quoteButton + " " + styles.infoButton} type='default' onClick={() => setQuoteModalVisible(!quoteModalVisible)}>Request A Quote</Button>
      <Button className={styles.infoButton} type='primary' onClick={() => setVisitModalVisible(!visitModalVisible)}>Request A Visit</Button> 
    </div>
  )

  return (
    <>
      <PageHeader
        className={styles.container}
        backIcon={false}
        onBack={() => window.history.back()}
        title={title}
        extra={[
           headerContent
        ]}
      >
      </PageHeader>
      <RequestQuote visible={quoteModalVisible} setVisible={setQuoteModalVisible} siteName="N/A" />
      <RequestVisit visible={visitModalVisible} setVisible={setVisitModalVisible} siteName="N/A" />
    </>
  )
}