import { gql } from "apollo-boost";

export const MARKETING_IMAGES = gql`
  query MarketingImages($limit: Int!, $page: Int!) {
    marketingImages(limit: $limit, page: $page) {
      total
      result {
        id
        actionId
        url
        createdAt
        updatedAt
      }
    }
  }
`;