import { Col, Row, Tooltip, Typography } from "antd";
import React from "react";
import { CircleRating } from "../../../../../../../../lib/components";
import { Rating } from "../../../../../../../../lib/graphql/globalTypes";
import { getRiskRatingColor, sentenceCase } from "../../../../../../../../lib/utils";
import { siteRiskRatingStyles } from "./styles";

interface Props {
  riskRating: Rating
}

const {Title, Text} = Typography

export const SiteRiskRating: React.FC<Props> = ({riskRating}) => {
  const styles = siteRiskRatingStyles();

  return (
    <>
    <Row>
      <Col span={8} className={styles.cotnainer}>
        <Title level={3}>Site Risk Rated:</Title>
        <Text style={{fontWeight: "600", color: getRiskRatingColor(riskRating)}}>{sentenceCase(riskRating)}</Text>
      </Col>
      <Col span={4}>
        <Tooltip title="Very High. Pest activity found in a customer area.">
        <span></span>
          <CircleRating title={"VERYHIGH"} selected={riskRating === Rating.VERYHIGH} />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip title="High. Pest activity found internally.">
          <span></span>
          <CircleRating title={"HIGH"} selected={riskRating === Rating.HIGH} />
        </Tooltip>      
      </Col>
      <Col span={4}>
        <Tooltip title="Medium. Pest activity found externally.">
        <span></span>
          <CircleRating title={"MEDIUM"} selected={riskRating === Rating.MEDIUM} />
        </Tooltip>
      </Col>
      <Col span={4}>
        <Tooltip title="Low. No pest activity found">
        <span></span>
          <CircleRating title={"LOW"} selected={riskRating === Rating.LOW} />
        </Tooltip>
      </Col>
    </Row>
    </>
    
  )
}