import { Col, Typography } from "antd";
import Color from "color";
import dayjs from "dayjs";
import React from "react";
import { getRiskRatingColor, sentenceCase } from "../../../../../../../../lib/utils";
import { base, colors } from "../../../../../../../../styles";

interface Props {
  data: any
}

const {Text} = Typography;

export const TrendBox: React.FC<Props> = ({data}) => {
  const defaultColor = Color(getRiskRatingColor(data.riskRating)).isDark() ? colors.white : colors.black

  return (
    <Col span={2} style={{textAlign: "center", display: "flex", alignItems: "center", flexDirection: "column"}}>
    <Text>{dayjs(data.date).format("DD-MM")}</Text><br/>
    {/* <Tag color={getRiskRatingColor(data.riskRating)}>{sentenceCase(data.riskRating)}</Tag><br/> */}
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", background: getRiskRatingColor(data.riskRating), width: "70px", height: "70px", borderRadius: "50px"}}>
      <Text style={{color: defaultColor, fontSize: base(0.7)}}>{sentenceCase(data.riskRating)}</Text>
    </div>
    <Text>{data.pestRisk}</Text>
  </Col>
  )
}