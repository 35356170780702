import { useQuery } from "@apollo/react-hooks"
import { Content } from "antd/lib/layout/layout"
import { SITE_ACTIONS_SUMMARY } from "../../../../lib/graphql/query/Action"
import { SiteActionsSummary, SiteActionsSummaryVariables } from "../../../../lib/graphql/query/Action/__generated__/SiteActionsSummary"
import { Site } from "../../../../lib/graphql/query/Site/__generated__/Site"
import { PerformanceIndicators, SiteTrends, UrgentActionsTable } from "./components"
import { UpcomingJobs } from "./components/UpcomingJobs"

interface Props {
  siteData: Site | undefined
}

export const Summary: React.FC<Props> = ({siteData}) => {

  const {data: siteActionsSummary} = useQuery<SiteActionsSummary, SiteActionsSummaryVariables>(SITE_ACTIONS_SUMMARY, {
    variables: {
      id: siteData?.site.id as string
    }
  })
  return (
    <Content>
      <PerformanceIndicators siteData={siteData} siteActionsSummary={siteActionsSummary} />
      <UrgentActionsTable siteId={siteData?.site.id} />
      <SiteTrends siteId={siteData?.site.id} />
      <UpcomingJobs siteId={siteData?.site.id} />
    </Content>
  )
}