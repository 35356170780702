import { gql } from 'apollo-boost';

export const CONTACTS = gql`
  query Contacts($active: Boolean, $limit: Int!, $page: Int!, $lookup: String, $includeSites: Boolean) {
    contacts(active: $active, limit: $limit, page: $page, lookup: $lookup, includeSites: $includeSites) {
      total,
      result {
        id
        contactId
        name
        active
        createdAt
        site {
          id,
          name
        }
        sites {
          id,
          name
        }
      }
    }
  }
`;
