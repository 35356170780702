import { gql } from 'apollo-boost';

export const USERS = gql`
  query Users($limit: Int!, $page: Int!, $lookup: String) {
    users(limit: $limit, page: $page, lookup: $lookup) {
      total,
      result {
        id
        firstName
        lastName
        email
        emailConfirmed
        phoneNumber
        phoneNumberConfirmed
        lockout
        receiveEmailNotifications
        receiveSmsNotifications
        userRole
        createdAt
      }
    }
  }
`;

export const USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      emailConfirmed
      phoneNumber
      phoneNumberConfirmed
      lockout
      receiveEmailNotifications
      receiveSmsNotifications
      userRole
      createdAt
      userSiteClaims {
        siteId
      }
    }
  }
`;
