import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Layout, Popconfirm, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { AddComplianceFile } from "../../lib/components";
import { COMPLIANCE_FILE, DELETE_COMPLIANCE_FILE } from "../../lib/graphql/mutation/Compliance";
import { ComplianceFile, ComplianceFileVariables } from "../../lib/graphql/mutation/Compliance/__generated__/ComplianceFile";
import { DeleteComplianceFile, DeleteComplianceFileVariables } from "../../lib/graphql/mutation/Compliance/__generated__/DeleteComplianceFile";
import { COMPLIANCE_FILES } from "../../lib/graphql/query/Compliance";
import { ComplianceFiles, ComplianceFilesVariables } from "../../lib/graphql/query/Compliance/__generated__/ComplianceFiles";
import { Viewer } from "../../lib/types";
import { displaySuccessNotification } from "../../lib/utils";
import { colors } from "../../styles";
import { complianceStyles } from "./styles";

interface Props {
  viewer: Viewer
}

const {Content} = Layout;
const {Title} = Typography

export const Compliance: React.FC<Props> = ({viewer}) => {
  const styles = complianceStyles();
  const [modalVisible, setModalVisible] = useState(false)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const {data, loading, refetch} = useQuery<ComplianceFiles, ComplianceFilesVariables>(COMPLIANCE_FILES, {
    variables: {
      page,
      limit
    }
  })

  const [getFile, {loading: loadingFile}] = useMutation<ComplianceFile, ComplianceFileVariables>(COMPLIANCE_FILE, {
    onCompleted: data => {
      if(data && data.complianceFile && data.complianceFile.fileName) {
        let fetchDataModified = `data:${data.complianceFile.type};base64,${data.complianceFile.file}`
        let a = document.createElement("a")
        a.href = fetchDataModified
        a.download = `${data.complianceFile.fileName}`
        a.click()
      }
    }
  })

  const downloadFile = (id: string) => {
    getFile({
      variables: {
        id
      }
    })
  }

  const [deleteComplianceFile] = useMutation<DeleteComplianceFile, DeleteComplianceFileVariables>(DELETE_COMPLIANCE_FILE, {
    onCompleted: data => {
      if(data) {
        displaySuccessNotification("File successfully deleted");
        refetch()
      }
    }
  })

  const columns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "File Size",
      dataIndex: "size",
      key: "size",
      render: (size: any) => (size / 1000 / 1000).toFixed(2) + " MB"
    },
    {
      title: "Type",
      dataIndex: "fileName",
      key: "fileName",
      render: (fileName: string) => fileName.split('.')[1].toUpperCase()
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: Date) => dayjs(date).format("DD-MM-YYYY")
    },
    {
      title: "Download File",
      render: (item: any) => (
        <Space>
          <Button loading={loadingFile} onClick={() => downloadFile(item.id)} type="link">Download</Button>
          {viewer?.userRole?.includes("ADMIN") && <Popconfirm title="Are you sure you want to delete this file?" okText="Confirm" onConfirm={() => deleteComplianceFile({variables: {id: item.id}})} ><Button type='link'><span style={{color: colors.red}}>Delete</span></Button></Popconfirm>}
        </Space>
      )
    }
  ]

  const closeModal = (isVisible: boolean) => {
    setModalVisible(isVisible);
    refetch()
  }

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  return (
    <Content className={styles.container}>
      <Card>
        <div className={styles.titleContainer}>
          <Title>Compliance Files</Title>
          {
            viewer?.userRole?.includes("ADMIN") && <Button type="primary" onClick={() => setModalVisible(!modalVisible)}>Add Site File</Button>
          }
        </div>
        <Table 
          dataSource={data?.complianceFiles.result as []} 
          columns={columns}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.complianceFiles.total,
            onChange: handlePaginationChange,
          }}
          loading={loading}
        />
      </Card>
      <AddComplianceFile setModalVisible={closeModal} modalVisible={modalVisible} />
    </Content>
  )
}