import { useMutation } from '@apollo/react-hooks';
import { Button, Form, Input, Layout, Typography } from 'antd';
import { Link, Navigate } from 'react-router-dom';
import { LOG_IN } from '../../lib/graphql/mutation/LogIn';
import { LogIn as LogInData, LogInVariables } from '../../lib/graphql/mutation/LogIn/__generated__/LogIn';
import { Viewer } from '../../lib/types';
import { displayErrorMessage, displaySuccessNotification } from '../../lib/utils';
import { base, spacing } from '../../styles';
import { ReactComponent as Logo } from './../../assets/logo.svg';
import { LoginStyles } from './style';

interface Props {
	setViewer: (viewer: Viewer) => void
}


const { Content } = Layout;
const { Title } = Typography
const { Item } = Form
const { Password } = Input

export const Login: React.FC<Props> = ({ setViewer }) => {
	const styles = LoginStyles();

	const [logIn, { loading, data }] = useMutation<LogInData, LogInVariables>(LOG_IN, {
		onCompleted: data => {
			if (data && data.logIn && !data.logIn.id) {
				displayErrorMessage('Email or password are incorrect!');
				return
			}
			setViewer(data.logIn);
			sessionStorage.setItem('token', data.logIn.token as string);
			displaySuccessNotification('You\'ve succesfully logged in!');
		},
		onError: (error) => {
			if (error.toString().includes("Account has been locked for too many failed login attempts")) {
				displayErrorMessage('Your account has been locked for too many failed logins. Please contact us to unlock your account.');
			} else {
				displayErrorMessage('Unable able to log you in. Please try again later.');
			}

		},
	});

	const handleLogInSubmit = (values: any) => {
		logIn({
			variables: {
				input: { ...values },
			},
		});
	};

	if (data && data.logIn.id) {
		return <Navigate to="/" />;
	}

	return (
		<Content className={styles.container}>
			<div className={styles.innerContainer}>
				<Title level={1}>Contego Action Management Portal</Title>
				<p>Reduce your pest risk</p>
			</div>
			<div className={styles.formContainer}>
				<div className={styles.logoContainer}>
					<Logo width={base(10)} className={styles.logo} />
				</div>
				<Form layout='vertical' onFinish={handleLogInSubmit}>
					<div>
					</div>
					<Item label="Email Address" name="email" rules={[{ required: true, message: 'Your email is required', type: 'email' }]}>
						<Input placeholder="john.doe@example.com" />
					</Item>
					<Item label="Password" name="password" rules={[{ required: true, message: 'Your password is required' }, {
						pattern: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,128}$/gm,
						message: 'Password requires a minimum eight characters, at least one uppercase, one lowercase and one number',
					}]}>
						<Password placeholder="**********" />
					</Item>
					<Item>
						<Button type="primary" htmlType="submit" loading={loading}>Log In</Button>
						<Link to="/forgot-password">
							<Button style={{ marginLeft: spacing.xSmall }} type='link'>Forgot your password</Button>
						</Link>
					</Item>
				</Form>
			</div>
		</Content>
	)
}