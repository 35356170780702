import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Col, Form, Image, Input, Modal, Row, Select, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { UPDATE_ACTION } from "../../graphql/mutation/Action";
import { UpdateAction as UpdateActionData, UpdateActionVariables } from "../../graphql/mutation/Action/__generated__/UpdateAction";
import { JOB_CARD } from "../../graphql/mutation/Job";
import { JobCard, JobCardVariables } from "../../graphql/mutation/Job/__generated__/JobCard";
import { ACTION } from "../../graphql/query/Action";
import { Action, ActionVariables } from "../../graphql/query/Action/__generated__/Action";
import { displayErrorMessage, displaySuccessNotification, getRiskRatingColor, sentenceCase } from "../../utils";
import { updateActionStyles } from "./styles";

interface Props {
  setModalVisible: (id: string | null) => void
  actionId?: string | null
  refetch?: () => void
}

const {Item} = Form;
const {Title, Text} = Typography;
const {Option} = Select;

export const UpdateAction: React.FC<Props> = ({setModalVisible, actionId, refetch: actionsRefetch}) => {
  const [imagesVisible, setImagesVisible] = useState(false)
  const {data, refetch} = useQuery<Action, ActionVariables>(ACTION, {
    variables: {
      id: actionId as string
    }
  })
  const [updateAction] = useMutation<UpdateActionData, UpdateActionVariables>(UPDATE_ACTION, {
    onCompleted: async data => {
      if(data && data.updateAction) {
        displaySuccessNotification("Action successfully updates");
        await refetch()
        if(actionsRefetch) {
          actionsRefetch()
        }
        setModalVisible(null)
      }
    },
    onError: (error) => {
      if(error) {
        displayErrorMessage("Something went wrong, please try again later.")
      }
    }
  });
  const [getJobCard, {loading: fileLoading}] = useMutation<JobCard, JobCardVariables>(JOB_CARD, {
    onCompleted: data => {
      if(data && data.jobCard) {
        let fetchDataModified = `data:application/pdf;base64,${data.jobCard}`
        let a = document.createElement("a")
        a.href = fetchDataModified
        a.download = `contego-jobcard-${dayjs().format("DD-MM-YYYY")}.pdf`
        a.click()
      }
    }
  })
  const styles = updateActionStyles();

  const [form] = Form.useForm();

  if(!actionId) {
    return null;
  }

  const handleUpdateAction = (input: any) => {
    updateAction({
      variables: {
        id: actionId,
        status: input.status,
        closeNotes: input.closeNotes
      }
    });
    form.resetFields();
  }

  const onJobCardFetch = () => {
    getJobCard({
      variables: {
        id: data?.action.jobId as string
      }
    });
  }

  return (
    <Modal
      visible={Boolean(actionId)}
      onCancel={() => setModalVisible(null)}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <Title level={2}>Update Action</Title>
      <Form initialValues={data?.action} layout="vertical" onFinish={handleUpdateAction}>
        <Row>
          <Col span={8}>
            <Title level={5}>Assigned To</Title>
            <Text>{data?.action.assignedTo && sentenceCase(data?.action.assignedTo)}</Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Status</Title>
            <Text>{data?.action.status && sentenceCase(data?.action.status)}</Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Type</Title>
            <Text>{data?.action.type && sentenceCase(data?.action.type)}</Text>
          </Col>
        </Row>
        <span>
          <Title level={5}>Site:</Title>
          <Text>{data?.action.site?.name}, {data?.action.site?.street}</Text>
        </span>
        <span>
          <Title level={5}>Details:</Title>
          <Text style={{color: getRiskRatingColor(data?.action.riskRating)}}>{data?.action.details}</Text>
        </span>
        <span>
          <Title level={5}>Description:</Title>
          <Text>{data?.action.description}</Text>
        </span>
        <span>
          <Title level={5}>{data?.action.images?.length} Images:</Title>
          <Button type="primary" onClick={() => {setImagesVisible(!imagesVisible)}}>View Images</Button>
          <Image.PreviewGroup
            preview={{
              visible: imagesVisible,
              onVisibleChange: value => {
                setImagesVisible(!imagesVisible);
              },
            }}
          >
            {
              data?.action.images?.map((image) => {
                return <Image 
                  style={{display: "none"}}
                  src={image.url}
                />
              })
            }
          </Image.PreviewGroup>
        </span>
        <span>
          <Title level={5}>Job Card</Title>
          <Button type="link" loading={fileLoading} onClick={() => onJobCardFetch()}>Download</Button>
        </span>
        <Item name="status" label="Update Status">
            <Select>
              <Option value="Incomplete">Incomplete</Option>
              <Option value="In Progress">In Progress</Option>
              <Option value="Completed">Completed</Option>
              <Option value="No Action">No Action</Option>
            </Select>
        </Item>
        <Item name="closeNotes" label="Close Notes">
            <Input.TextArea />
        </Item>
        <Item>
            <Button type="primary" htmlType="submit">Update</Button>
        </Item>
      </Form>
    </Modal>    
  )
}