import { createUseStyles } from 'react-jss';
import { colors, spacing } from '../../styles';

export const forgotPasswordStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logoContainer: {
    paddingTop: spacing.small,
    paddingBottom: spacing.small,
    display: "flex",
    justifyContent: "center"
  },
  logo: {
    fill: colors.green,
  }
})