import { createUseStyles } from 'react-jss';
import { base, colors, h3, queries, spacing } from '../../../../../../styles';

export const performanceIndicatorStyles = createUseStyles({
  container: {
    padding: spacing.small
  },
  factCard: {
    background: colors.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: base(10)
  },
  factCardText: {
    color: colors.green,
    fontWeight: 600,
    fontSize: h3.fontSize
  },
  actionsListContainer: {
    "& .ant-card-body": {
      padding: "0 !important",
    },
    "& *.canvas": {
      display: "block !important"
    },
    backgroundColor: "rgba(0,0,0,0)",
  },
  actionsList: {
    display: "flex",
    flexDirection: "column",
  },
  pestRiskContainer: {
    backgroundColor: "rgba(0,0,0,0)"
  },
  masonryGrid: {
    "& *": {
      background: "#d1d1d1",
    }
  },
  noDaysText: {
    marginTop: spacing.small
  },
  riskRatingsContainers: {
    borderLeft: "1px solid black", 
    paddingLeft: spacing.medium, 
    marginLeft: spacing.medium,
    [queries.l]: {
      marginLeft: 0,
      paddingLeft: 0,
      border: "none"
    }
  }
})