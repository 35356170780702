import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Layout, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { AddSitePlan } from "../../../../lib/components";
import { Viewer } from "../../../../lib/types";
import { sitePlansStyles } from "./styles";
import { SitePlans as SitePlansQuery, SitePlansVariables } from "../../../../lib/graphql/query/SitePlan/__generated__/SitePlans";
import { SITE_PLANS } from "../../../../lib/graphql/query/SitePlan";
import { SitePlan, SitePlanVariables } from "../../../../lib/graphql/mutation/SitePlans/__generated__/SitePlan";
import { SITE_PLAN } from "../../../../lib/graphql/mutation/SitePlans";

interface Props {
  siteId: string | undefined
  viewer: Viewer
}

const { Content } = Layout;
const { Title } = Typography

export const SitePlans: React.FC<Props> = ({ siteId, viewer }) => {
  const styles = sitePlansStyles();
  const [modalVisible, setModalVisible] = useState(false)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const { data, loading, refetch } = useQuery<SitePlansQuery, SitePlansVariables>(SITE_PLANS, {
    variables: {
      id: siteId as string,
      page,
      limit
    }
  })

  const [getFile, { loading: loadingFile }] = useMutation<SitePlan, SitePlanVariables>(SITE_PLAN, {
    onCompleted: data => {
      if (data && data.sitePlan && data.sitePlan.fileName) {
        if (data.sitePlan.file.match(/^http/)) {
          window.open(data.sitePlan.file, '_blank');
        } else {
          let fetchDataModified = `data:${data.sitePlan.type};base64,${data.sitePlan.file}`;
          let a = document.createElement("a");
          a.href = fetchDataModified;
          a.download = `${data.sitePlan.fileName}`;
          a.click();
        }
      }
    }
  })

  const downloadFile = (id: string) => {
    getFile({
      variables: {
        id
      }
    })
  }

  const columns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Job Reference",
      dataIndex: "jobReference",
      key: "jobReference",
    },
    {
      title: "File Size",
      dataIndex: "size",
      key: "size",
      render: (size: any) => (size / 1000 / 1000).toFixed(2) + " MB"
    },
    {
      title: "Type",
      dataIndex: "fileName",
      key: "fileName",
      render: (fileName: string) => fileName?.split('.')[1]?.toUpperCase()
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: Date) => dayjs(date).format("DD-MM-YYYY")
    },
    {
      title: "Download File",
      render: (item: any) => (
        <Space>
          <Button loading={loadingFile} onClick={() => downloadFile(item.id)} type="link">Download</Button>
        </Space>
      )
    }
  ]

  const closeModal = (isVisible: boolean) => {
    refetch()
    setModalVisible(isVisible);
  }

  const handlePaginationChange = (page: number, size: number) => {
    setPage(page);
    setLimit(size);
  };

  return (
    <Content className={styles.container}>
      <Card>
        <div className={styles.titleContainer}>
          <Title>Site Plans</Title>
          {
            (viewer?.userRole?.includes("ADMIN") || viewer?.userRole?.includes("ACCOUNTMANAGER")) && <Button type="primary" onClick={() => setModalVisible(!modalVisible)}>Add Site File</Button>
          }
        </div>
        <Table
          dataSource={data?.sitePlans.result as []}
          columns={columns}
          pagination={{
            defaultPageSize: limit,
            defaultCurrent: page,
            total: data?.sitePlans.total,
            onChange: handlePaginationChange,
          }}
          loading={loading}
        />
      </Card>
      <AddSitePlan setModalVisible={closeModal} modalVisible={modalVisible} siteId={siteId as string} />
    </Content>
  )
}