import { createUseStyles } from 'react-jss';
import { queries, spacing } from '../../../../../../styles';

export const siteTrendsStyles = createUseStyles({
  container: {
    marginTop: spacing.medium,
    overflowX: "auto",
    [queries.l]: {
      display: "none"
    }
  }
})