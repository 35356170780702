import { useQuery } from "@apollo/react-hooks"
import { Route, Routes, useParams } from "react-router-dom"
import { LoadingPage } from "../../lib/components"
import { SITE } from "../../lib/graphql/query/Site"
import { Site as SiteData, SiteVariables } from "../../lib/graphql/query/Site/__generated__/Site"
import { Viewer } from "../../lib/types"
import { Header } from "./components"
import { Actions, Files, GenerateSiteReport, Jobs } from "./Sections"
import { Summary } from "./Sections/Summary"
import { siteDashboardStyles } from "./styles"
import { SitePlans } from "./Sections"

interface Props {
  viewer: Viewer
}

export const SiteDashboard: React.FC<Props> = ({viewer}) => {
  const styles = siteDashboardStyles()
  const {siteId} = useParams();
  const {data} = useQuery<SiteData, SiteVariables>(SITE, {
    variables: {
      id: siteId as string
    },
  })

  if(!siteId) {
    return (
      <LoadingPage />
    )
  }

  

  return (
    <div className={styles.container}>
      <Header siteData={data} viewer={viewer} />
      <Routes>
        <Route path="/" element={<Summary siteData={data}  />} />
        <Route path="/jobs" element={<Jobs siteData={data} viewer={viewer} />} />
        <Route path="/actions" element={<Actions siteData={data} viewer={viewer} />} />
        <Route path="/files" element={<Files siteId={data?.site.id} viewer={viewer} />} />
        <Route path="/site-plans" element={<SitePlans siteId={data?.site.id} viewer={viewer} />} />
        <Route path="/reports" element={<GenerateSiteReport siteId={data?.site.id} />} />
      </Routes>
    </div>
  )    
}