import { Card, Col, Row, Tag, Tooltip, Typography } from 'antd';
import React from 'react';
import { ReactComponent as Logo } from '../../../../../../assets/logo.svg';
import { ReactComponent as Person } from '../../../../../../assets/person.svg';
import { LoadingPage } from '../../../../../../lib/components';
import { SiteActionsSummary } from '../../../../../../lib/graphql/query/Action/__generated__/SiteActionsSummary';
import { Site } from '../../../../../../lib/graphql/query/Site/__generated__/Site';
import { base, colors, spacing } from '../../../../../../styles';
import { ActionsDonutPlot, PestRating, PreviousRating, SiteRiskRating } from './components';
import { RecommendationTypesPlot } from './components/RecommendationTypesPlot';
import { performanceIndicatorStyles } from './styles';

interface Props {
  siteData: Site | undefined
  siteActionsSummary: SiteActionsSummary | undefined
}

export const PerformanceIndicators: React.FC<Props> = ({ siteData, siteActionsSummary }) => {
  const styles = performanceIndicatorStyles();

  const { Title, Text } = Typography;

  if (!siteData || !siteData.site) {
    return (<LoadingPage />)
  }

  return (
    <div className={styles.container}>
      <div>
        <Row style={{ paddingTop: spacing.medium, paddingBottom: spacing.small }}>
          <Col xxl={10} lg={12} md={24}>
            {
              siteData.site.ratings && siteData?.site?.ratings[0] ? (
                <>
                  <SiteRiskRating riskRating={siteData?.site?.ratings[0].riskRating} />
                </>
              ) : <Col xxl={10} lg={12} md={24} />
            }
          </Col>

          {
            siteData.site.ratings && siteData?.site?.ratings[1] ? (
              <>
                <Col xxl={6} lg={12} md={24} className={styles.riskRatingsContainers}>
                  <PreviousRating riskRating={siteData?.site?.ratings[1].riskRating} />
                </Col>
              </>
            ) : <Col xxl={6} lg={12} md={24} />
          }

          {
            siteData.site.ratings && siteData.site.ratings[0] && siteData.site.ratings[0] && siteData.site.ratings[0].pestRisk?.length ? (
              <Col xxl={6} lg={12} md={24} className={styles.riskRatingsContainers}>
                <PestRating pestRisk={siteData.site.ratings[0].pestRisk} riskRating={siteData?.site?.ratings[0].riskRating} />
              </Col>
            ) : (
              <Col xxl={6} lg={12} md={24} />
            )
          }

          {
            siteData.site.previousVisit && (
              <span className={styles.noDaysText}>
                <Text>Days since last visit: {siteData.site.previousVisit && siteData.site.previousVisit * -1}</Text>
                <Text style={{ fontSize: "20px", color: colors.red, fontWeight: "600" }}> {siteData.site.inEscalation && (
                  <Tooltip title="This site has been marked as escalated due to continued pest activity for more than 21 days.">
                    <Tag style={{ fontSize: base(0.7), fontWeight: "600", paddingTop: base(0.2), paddingBottom: base(0.2) }} color={colors.red}>SITE IN ESCALATION</Tag>
                  </Tooltip>

                )}</Text>
              </span>
            )
          }
          {/* TODO: add escalation field */}


        </Row>

        <br />
        <Row>
          <Col xxl={8} md={12} xs={24}>
            <Title level={4}>Actions to review</Title>
          </Col>
          <Col xxl={8} md={12} xs={24}>
            <Title level={4}>Actions by Risk Ratings</Title>
          </Col>
          <Col xxl={8} md={12} xs={24}>
            <Title level={4}>Recommendation by Types</Title>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xxl={4} md={12} xs={24}>
            <Card className={styles.factCard}>
              <Person style={{ fill: colors.black }} width={base(4)} /><br />
              <Text className={styles.factCardText} style={{ color: colors.black }}>{siteActionsSummary?.siteActionsSummary.customerActions}</Text><br />
              <Text style={{ fontWeight: "600" }}>Your Urgent Actions</Text>
            </Card>
          </Col>
          <Col xxl={4} md={12} xs={24}>
            <Card className={styles.factCard}>
              <Logo style={{ fill: colors.green }} />
              <Text className={styles.factCardText}>{siteActionsSummary?.siteActionsSummary.contegoActions}</Text><br />
              <Text style={{ fontWeight: "600" }}>Contego Actions</Text>
            </Card>
          </Col>
          <Col xxl={8} md={8} xs={24}>
            <Card className={styles.actionsListContainer}>
              <ActionsDonutPlot
                veryHigh={siteActionsSummary?.siteActionsSummary.veryHighRisk}
                high={siteActionsSummary?.siteActionsSummary.highRisk}
                medium={siteActionsSummary?.siteActionsSummary.mediumRisk}
                low={siteActionsSummary?.siteActionsSummary.lowRisk}
              />
            </Card>
          </Col>
          <Col xxl={8} md={8} xs={24}>
            <Card className={styles.actionsListContainer}>
              <RecommendationTypesPlot
                buildingCondition={siteActionsSummary?.siteActionsSummary.buildingCondition}
                pestActivity={siteActionsSummary?.siteActionsSummary.pestActivity}
                hygiene={siteActionsSummary?.siteActionsSummary.hygiene}
                proofing={siteActionsSummary?.siteActionsSummary.proofing}
                observational={siteActionsSummary?.siteActionsSummary.observational}
                service={siteActionsSummary?.siteActionsSummary.service}
              />
            </Card>
          </Col>
          {/* <Col span={8}>
            {
              siteData.site.ratings && siteData.site.ratings[0] ? (
                <Card className={styles.pestRiskContainer}>
                  <Text>Pest Risk On Site </Text><Tooltip title="These are the pests we found on our last visit"><InfoCircleFilled /></Tooltip>
                  <div className={styles.masonryGrid}>
                    <span>{siteData.site.ratings[0].pestRisk}</span>
                  </div>
                </Card>
              ) : (
                null
              )
            }
            
          </Col> */}
        </Row>
      </div>
    </div>
  )
}