import { Card, Col, Layout, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as Bird } from '../../../../assets/bird.svg';
import { ReactComponent as FlyingInsects } from '../../../../assets/flyingInsect.svg';
import { ReactComponent as Fox } from '../../../../assets/fox.svg';
import { ReactComponent as Insect } from '../../../../assets/insect.svg';
import { ReactComponent as Mouse } from '../../../../assets/mouse.svg';
import { ReactComponent as Rat } from '../../../../assets/rat.svg';
import { SitesSummary } from "../../../../lib/graphql/query/Dashboard/__generated__/SitesSummary";
import { base } from "../../../../styles";
import { CategoryCard } from "./components";
import { sitesPerCategoryStyles } from "./styles";

interface Props {
  siteData: SitesSummary | undefined
}

const {Content} = Layout;
const {Title} = Typography;

export const SitesPerCategory: React.FC<Props> = ({siteData}) => {
  const styles = sitesPerCategoryStyles();

  if(!siteData) {
    return null
  }

  return (
    <Content className={styles.container}>
      <Card>
        <Title level={3}>No. of Risk Sites By Pest Type</Title>
        <Row gutter={24} style={{paddingTop: base(2)}}>
          <Col span={4}>
            <Link to='/sites?pest=rats'>
              <CategoryCard title="Rats" value={siteData.sitesSummary.rats} icon={<Rat className={styles.icon} />} />
            </Link>
          </Col>
          <Col span={4}>
            <Link to='/sites?pest=mice'>
           <  CategoryCard title="Mice" value={siteData.sitesSummary.mice} icon={<Mouse className={styles.icon} />} />
            </Link>
          </Col>
          <Col span={4}>
            <Link to='/sites?pest=birds'>
              <CategoryCard title="Birds" value={siteData.sitesSummary.avian} icon={<Bird className={styles.icon} />} />
            </Link>
          </Col>
          <Col span={4}>
            <Link to='/sites?pest=crawlingInsects'>
              <CategoryCard title="Crawling Insects" value={siteData.sitesSummary.insects} icon={<Insect className={styles.icon} />} />
            </Link>
          </Col>
          <Col span={4}>
            <Link to='/sites?pest=flyingInsects'>
              <CategoryCard title="Flying Insects" value={siteData.sitesSummary.flyingInsects} icon={<FlyingInsects className={styles.icon} />} />
            </Link>
          </Col>
          <Col span={4}>
            <Link to='/sites?pest=wildlife'>
              <CategoryCard title="Wildlife" value={siteData.sitesSummary.wild} icon={<Fox className={styles.icon} />} />
            </Link>
          </Col>
        </Row>
      </Card>
    </Content>
    
  )
}