import { createUseStyles } from 'react-jss';
import { base, colors, spacing } from '../../../../../../styles';

export const categoryCardStyles = createUseStyles({
  container: {
    background: colors.black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: spacing.small
  },
  text: {
    color: colors.white,
    fontWeight: "600"
  },
  iconContainer: {
    backgroundColor: colors.green,
    padding: spacing.small,
    width: base(4),
    height: base(4),
    borderRadius: base(2),
    marginTop: "-"+base(3),
    marginBottom: base(0.5)
  },
})