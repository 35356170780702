import Color from 'color';
import { createUseStyles } from 'react-jss';
import { base, colors } from '../../../styles';

export const circleRatingStyles = createUseStyles({
  container: ({color, selected, size}: {color: string, selected: boolean, size: number}) => ({
    display: "flex",
    height: size,
    width: size,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: selected && `2px solid ${colors.white}`,
    boxShadow: selected && `0 0 0 6px ${color}`,
    borderRadius: `100%`,
    backgroundColor: color,
    '& *': {
      color: Color(color).isDark() && colors.white
    },
  }),
  title: {
    fontSize: base(0.7)
  }
})