import { gql } from "apollo-boost";

export const ADD_COMPLIANCE_FILE = gql`
  mutation AddComplianceFile($input: AddComplianceFileInput!) {
    addComplianceFile(input: $input) {
      id
    }
  }
`

export const COMPLIANCE_FILE = gql`
  mutation ComplianceFile($id: String!) {
    complianceFile(id: $id) {
      file
      fileName
      type
    }
  }
`;

export const DELETE_COMPLIANCE_FILE = gql`
  mutation DeleteComplianceFile($id: String!) {
    deleteComplianceFile(id: $id)
  }
`;