import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Layout, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { UpdateAction } from "../../../../lib/components";
import { SITE_ACTIONS } from "../../../../lib/graphql/query/Action";
import { SiteActions, SiteActionsVariables } from "../../../../lib/graphql/query/Action/__generated__/SiteActions";
import { Site } from "../../../../lib/graphql/query/Site/__generated__/Site";
import { Viewer } from "../../../../lib/types";
import { sentenceCase } from "../../../../lib/utils";
import { base, colors } from "../../../../styles";
import { actionsStyles } from "./styles";

interface Props {
  siteData: Site | undefined
  viewer: Viewer
}

const {Content} = Layout;
const {Title} = Typography;

export const Actions:React.FC<Props> = ({siteData, viewer}) => {
  const [selectedAction, setSelectedAction] = useState<null | string>(null)

  const [sort, setSort] = useState({key: "date", order: "desc"})
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const {data, loading} = useQuery<SiteActions, SiteActionsVariables>(SITE_ACTIONS, {
    variables: {
      id: siteData?.site.id as string,
      limit,
      page,
      sort,
    }
  })

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: base(7.5),
      sorter: true,
      render: (date: Date) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: base(7.5),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
      sorter: true,
      width: base(7.5),
      render: (assignedTo: string) => sentenceCase(assignedTo)
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status: string) => {
        if(status.includes("Completed")) {
          return <Tag color={colors.green}>{status}</Tag>
        }
        return <Tag>{status}</Tag>
      }
    },
    {
      title: "Risk Rating",
      dataIndex: "riskRating",
      key: "riskRating",
      render: (riskRating: string) => {
        switch(riskRating) {
          case "VERYHIGH":
            return <Tag color={colors.black}>Very High</Tag>
          case "HIGH":
            return <Tag color={colors.red}>High</Tag>
          case "MEDIUM":
            return <Tag color={colors.orange}>Medium</Tag>
          case "LOW":
            return <Tag color={colors.lowRating}>Low</Tag>
          default:
            return null
        }
      }
    },
    {
      title: "Close Notes",
      dataIndex: "closeNotes",
      key: "closeNotes",
      width: base(12),
    },
    {
      title: "Update",
      key: "update",
      render: (item: any) => (
          (item.assignedTo === "CUSTOMER" || viewer?.userRole?.includes("ADMIN")) && <Button type="link" onClick={() => setSelectedAction(item.id)}>Update</Button>
        )
    }
  ]

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSort({
      key: sorter.columnKey,
      order: sorter.order === "ascend" ? "asc" : "desc"
    })
  }
  const styles = actionsStyles();

  return (
    <Content className={styles.container}>
      <Card>
        <Title>Actions</Title>
        <Table 
        dataSource={data?.siteActions.result} 
        columns={columns}
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          total: data?.siteActions.total,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        loading={loading}
      />
      </Card>
      <UpdateAction setModalVisible={setSelectedAction} actionId={selectedAction} />
    </Content>
  )
}