import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { ACTIVATE_CONTACT } from "../../../../lib/graphql/mutation/Contact";
import { ActivateContact, ActivateContactVariables } from "../../../../lib/graphql/mutation/Contact/__generated__/ActivateContact";
import { CONTACTS } from "../../../../lib/graphql/query/Contact";
import { Contacts as ContactsData, ContactsVariables, Contacts_contacts_result } from "../../../../lib/graphql/query/Contact/__generated__/Contacts";
import { displaySuccessNotification } from "../../../../lib/utils";
import { contactStyles } from "../../styles";

interface Props {
  refresh: number
  setRefresh: (i: number) => void
}

export const ContactsTable: React.FC<Props> = ({refresh, setRefresh}) => {
  const styles = contactStyles();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [lookup, setLookup] = useState<string>("")
  const {data, loading, refetch} = useQuery<ContactsData, ContactsVariables>(CONTACTS, {
    variables: {
      // active: false,
      limit,
      page,
      lookup
    }
  });
  const [activateContact] = useMutation<ActivateContact, ActivateContactVariables>(ACTIVATE_CONTACT, {
    onCompleted: data => {
      displaySuccessNotification("Contact successfully activated")
    }
  })

  useEffect(() => {
    refetch()
  }, [refresh, refetch])

  const handleActivateClick = (contactId: string) => {
    activateContact({
      variables: {
        contactId
      }
    });
    setRefresh(refresh + 1)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Active",
      render: (record: Contacts_contacts_result) => <Button onClick={() => handleActivateClick(record.id)} type="link">Activate</Button>
    }
  ]

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleLookupChange = (evt: any) => {
		setLookup(evt.target.value);
	};

  return (
    <>
      <Input className={styles.searchInput} placeholder="Search" onChange={evt => handleLookupChange(evt)} />
      <Table 
        columns={columns}
        dataSource={data?.contacts.result}
        loading={loading}
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          total: data?.contacts.total,
          onChange: handlePaginationChange,
        }}
      />
    </>
  )
}