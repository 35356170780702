import { createUseStyles } from 'react-jss';
import { base, spacing } from '../../styles';

export const quotesStyles = createUseStyles({
  container: {
    marginTop: spacing.medium
  },
  titleContainer: {
    display: "flex",
    alignItems: "center"
  },
  addQuoteButton: {
    height: base(2),
    marginLeft: spacing.medium
  },
  searchInput: {
    margin: "20px 0",
    maxWidth: base(15),
    float: "right"
  },
  table: {
    overflowX: "auto"
  }
})