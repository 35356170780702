import React from "react";
import { Form, Modal, Select } from "antd";
import { Button } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { ToggleAutoCloseSiteActions, ToggleAutoCloseSiteActionsVariables } from "../../graphql/mutation/Site/__generated__/ToggleAutoCloseSiteActions";
import { TOGGLE_AUTO_CLOSE_SITE_ACTIONS } from "../../graphql/mutation/Site";
import { displayErrorMessage, displaySuccessNotification } from "../../utils";

interface Props {
  visible: boolean;
  onClose: () => void;
  currentAutoCloseSetting?: boolean
  siteId: string
}

const { Item } = Form;
const { Option } = Select;

export const UpdateSiteAutoCloseActions: React.FC<Props> = ({ visible, onClose, currentAutoCloseSetting, siteId }) => {

  const [toggleAutoCloseSiteActions] = useMutation<ToggleAutoCloseSiteActions, ToggleAutoCloseSiteActionsVariables>(TOGGLE_AUTO_CLOSE_SITE_ACTIONS, {
    onCompleted(data) {
      if (data && data.toggleAutoCloseSiteActions) {
        displaySuccessNotification("Site auto close action updated successfully");
      }
    },
    onError(error) {
      if (error) {
        displayErrorMessage('Error updating site auto close actions');
      }
    },
  })

  const onFinish = (values: any) => {
    toggleAutoCloseSiteActions({
      variables: {
        siteId: siteId,
        autoClose: values.autoClose === "true" ? true : false
      }
    })
  }

  return (
    <Modal visible={visible} onCancel={onClose} footer={false}>
      <h2>Update Site Risk Rating</h2>
      <Form layout="vertical" initialValues={{ autoClose: currentAutoCloseSetting }} onFinish={onFinish}>
        <Item name="autoClose" label="Auto Close Actions">
          <Select>
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
          </Select>
        </Item>
        <Item>
          <Button type="primary" htmlType="submit">Update</Button>
        </Item>
      </Form>
    </Modal>
  )
}