import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Col, DatePicker, Form, Input, Layout, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import { ActionOwner } from "../../lib/graphql/globalTypes";
import { CLOSE_ACTIONS } from "../../lib/graphql/mutation/Action";
import { CloseActions as CloseActionsData, CloseActionsVariables } from "../../lib/graphql/mutation/Action/__generated__/CloseActions";
import { SITES } from "../../lib/graphql/query/Site";
import { Sites, SitesVariables } from "../../lib/graphql/query/Site/__generated__/Sites";
import { displayErrorMessage, displaySuccessNotification } from "../../lib/utils";
import { addQuoteStyles } from "./styles";

const {Content} = Layout;
const {Title} = Typography;
const {Item} = Form;
const {Option} = Select;

export const CloseActions: React.FC = () => {
  const [lookup, setLookup] = useState("")
  const {data: siteData} = useQuery<Sites, SitesVariables>(SITES, {
    variables: {
      limit: 50,
      page: 1,
      lookup,
      sort: {key: "name", order: "asc"}
    }
  })

  const [closeActions] = useMutation<CloseActionsData, CloseActionsVariables>(CLOSE_ACTIONS, {
    onCompleted: (data) => {
      if(data && data.closeActions) {
        displaySuccessNotification("Actions successfully closed")
      }
    },
    onError: (error) => {
      if(error) {
        displayErrorMessage("Something went wrong, please try again later.")
      }
    }
  })

  const styles = addQuoteStyles()

  const handleSiteLookup = (search: any) => {
    setLookup(search)
  }

  const handleFormSubmit = (input: any) => {
    closeActions({
      variables: {
        input: {
          ...input,
        }
      }
    })
  }

  return (
    <Content className={styles.container}>
      <Row>
        <Col span={12}>
          <Card>
            <Title>Close Actions</Title>
            <Form layout="vertical" onFinish={handleFormSubmit}>
              <Item name="actionOwner" label="Action Owner" rules={[{required: true, message: "Action Owner is required"}]}>
                <Select>
                  <Option value={ActionOwner.CONTEGO}>Contego</Option>
                  <Option value={ActionOwner.CUSTOMER}>Customer</Option>
                </Select>
              </Item>
              <Item name="siteId" label="Site" rules={[{required: true, message: "Site is required"}]}>
              <Select
                  showSearch
                  placeholder="Search for a site"
                  onSearch={handleSiteLookup}
                  filterOption={false}
                  notFoundContent={lookup.length > 2 ? "No contacts found" : "Try searching the first 3 characters of the contact name"}
                >
                  {
                    siteData?.sites.result.map((site: any) => {
                      return <Option key={site.id} value={site.id}>{site.name}</Option>
                    })
                  }
                </Select>
              </Item>
              <Row>
                <Col span={12}>
                  <Item name="startDate" label="Start Date" rules={[{required: true, message: "Start Date is required"}]}>
                    <DatePicker />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item name="endDate" label="End Date" rules={[{required: true, message: "End Date is required"}]}>
                    <DatePicker />
                  </Item>
                </Col>
              </Row>
              <Item name="closeNotes" label="Close Notes">
                <Input.TextArea />
              </Item>
              <Item>
                <Button type="primary" htmlType="submit">Close Actions</Button>
              </Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Content>
  )
}