import { createUseStyles } from "react-jss";
import { base, queries, spacing, h3 } from "../../styles";

export const newPortalNoticeStyles = createUseStyles({
  container: {
    zIndex: 1000,
    backgroundColor: '#f7f9fb',
    borderRadius: base(0.5),
    bottom: '100%',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.25)',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xSmall,
    marginBottom: spacing.xSmall,
    opacity: 0.9,
    padding: spacing.xSmall,
    position: 'absolute',
    right: 0,
    left: '50%',
    top: base(),
    transform: 'translateX(-50%)',
    height: 'fit-content',
    maxWidth: '80vw',

    '& *': {
      margin: 0,
      lineHeight: '1em',
    },

    '& h2': {
      ...h3,
    },

    [queries.s]: {
      display: 'none',
    },
  },
  closeButton: {
    position: 'absolute',
    right: base(0.5),
    top: base(0.5),
  }
})