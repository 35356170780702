import { useQuery } from "@apollo/react-hooks";
import { Card, Col, Layout, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Sites } from "..";
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Person } from '../../assets/person.svg';
import { CircleRatingNumber } from "../../lib/components/CircleRatingNumber";
import { ACTIONS_SUMMARY, SITES_SUMMARY } from "../../lib/graphql/query/Dashboard";
import { ActionsSummary } from "../../lib/graphql/query/Dashboard/__generated__/ActionsSummary";
import { SitesSummary } from "../../lib/graphql/query/Dashboard/__generated__/SitesSummary";
import { Viewer } from "../../lib/types";
import { base, colors, spacing } from "../../styles";
import { Actions } from "../Actions";
import { ActionsDonutPlot, Header, SiteRiskBarPlot, SitesPerCategory } from "./components";
import { dashboardStyles } from "./styles";

interface Props {
  viewer: Viewer
}

const {Content} = Layout;
const {Title, Text} = Typography;

export const Dashboard: React.FC<Props> = ({viewer}) => {
  const {data: actionsData, refetch: actionRefetch} = useQuery<ActionsSummary>(ACTIONS_SUMMARY)
  const {data: sitesData, refetch: siteRefetch} = useQuery<SitesSummary>(SITES_SUMMARY)
  const styles = dashboardStyles();

  useEffect(() => {
    actionRefetch();
    siteRefetch();
  }, [actionRefetch, siteRefetch])

  const escalationColor = sitesData?.sitesSummary.sitesAtRisk && sitesData?.sitesSummary.sitesAtRisk >= 1 ? colors.red : colors.green
  const escalationText = sitesData?.sitesSummary.sitesAtRisk && sitesData?.sitesSummary.sitesAtRisk >= 1 ? colors.white : colors.white
  return (
    <div className={styles.totalContainer}>
      <Header />
      <Content className={styles.container}>
        <Content style={{paddingBottom: 0}}>
          <Row gutter={24} style={{marginBottom: spacing.small}}>
            <Col xxl={8} md={12} xs={24}>
              <Card>
                <Title level={2}>Open Actions</Title>
                <ActionsDonutPlot
                  veryHigh={actionsData?.actionsSummary.veryHighRisk}
                  high={actionsData?.actionsSummary.highRisk}
                  medium={actionsData?.actionsSummary.mediumRisk}
                  low={actionsData?.actionsSummary.lowRisk}
                />
              </Card>
            </Col>
            <Col xxl={8} md={12} xs={24}>
              <Card>
                <Title level={2}>Site Ratings</Title>
                <SiteRiskBarPlot 
                  veryHigh={sitesData?.sitesSummary.veryHighRisk}
                  high={sitesData?.sitesSummary.highRisk}
                  medium={sitesData?.sitesSummary.mediumRisk}
                  low={sitesData?.sitesSummary.lowRisk}
                />
              </Card>
            </Col>
            <Col xxl={8} md={24} xs={24}>
              <Row gutter={24}>
                <Col xxl={12} md={12} xs={24}>
                  <Card className={styles.factCard}>
                    <Person style={{fill: colors.black}} width={base(4)} /><br/>
                    <Text className={styles.factCardText} style={{color: colors.black}}>{actionsData?.actionsSummary.customerActions}</Text><br />
                    <Text style={{fontWeight: "600"}}>Your Urgent Actions</Text>
                  </Card>
                </Col>
                <Col xxl={12} md={12} xs={24}>
                  <Card className={styles.factCard}>
                    <Logo style={{fill: colors.green}} />
                    <Text className={styles.factCardText}>{actionsData?.actionsSummary.contegoActions}</Text><br />
                    <Text style={{fontWeight: "600", color: colors.green}}>Contego Actions</Text>
                  </Card>
                </Col>
              </Row>
              <Row gutter={24} style={{marginTop: spacing.small}}>
              <Col xxl={12} md={12} xs={24}>
                <Card className={styles.infoCard}>
                <Text className={styles.infoCardText}>{sitesData?.sitesSummary && sitesData?.sitesSummary.highRisk + sitesData?.sitesSummary.highRisk + sitesData?.sitesSummary.mediumRisk + sitesData?.sitesSummary.lowRisk}</Text><br />
                  <Text style={{fontWeight: "600", color: colors.white}}>Total Sites</Text>
                </Card> 
              </Col>
              <Col xxl={12} md={12} xs={24}>
                <Link to={`/sites?inEscalation=true`}>
                  <Card className={styles.escalationCard} style={{background: escalationColor}}>
                    <Text className={styles.infoCardText} style={{color: escalationText}}>{sitesData?.sitesSummary.sitesAtRisk}</Text><br />
                    <Text style={{fontWeight: "600", color: escalationText }}>Sites in Escalation</Text>
                  </Card> 
                </Link>
              </Col> 
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={24} sm={0}>
              <Card>
                <Row gutter={24}>
                  <Col span={8}>
                    <Title level={3}>No. of Sites By Risk Rate</Title>
                  </Col>
                  <Col span={4} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Link to='/sites?riskRating=veryhigh' style={{display: "flex", flexDirection: "column"}}>
                      <CircleRatingNumber title={"VERYHIGH"} number={sitesData?.sitesSummary.veryHighRisk} />
                      <Text>Very High Risk</Text>
                    </Link>
                  </Col>
                  <Col span={4} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Link to='/sites?riskRating=high' style={{display: "flex", flexDirection: "column"}}>
                      <CircleRatingNumber title={"HIGH"} number={sitesData?.sitesSummary.highRisk} />
                      <Text>High Risk</Text>
                    </Link>
                  </Col>
                  <Col span={4} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Link to='/sites?riskRating=medium' style={{display: "flex", flexDirection: "column"}}>
                      <CircleRatingNumber title={"MEDIUM"} number={sitesData?.sitesSummary.mediumRisk} />
                      <Text>Medium Risk</Text>
                    </Link>
                  </Col>
                  <Col span={4} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <Link to='/sites?riskRating=low' style={{display: "flex", flexDirection: "column"}}>
                      <CircleRatingNumber title={"LOW"} number={sitesData?.sitesSummary.lowRisk} />
                      <Text>Low Risk</Text>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
        <SitesPerCategory siteData={sitesData} />
        <Sites initialLimit={5} />
          <Actions initialLimit={5} viewer={viewer} />
      </Content>
    </div>
  )
}