import { createUseStyles } from 'react-jss';
import { base, spacing } from '../../styles';

export const sitesStyles = createUseStyles({
  container: {
    marginTop: spacing.medium
  },
  searchInput: {
    margin: "20px 0",
    maxWidth: base(15),
    float: "right"
  },
  table: {
    overflowX: "auto"
  }
})