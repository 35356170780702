import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Layout, Popconfirm, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { DELETE_JOB, JOB_CARD } from "../../../../lib/graphql/mutation/Job";
import { DeleteJob, DeleteJobVariables } from "../../../../lib/graphql/mutation/Job/__generated__/DeleteJob";
import { JobCard, JobCardVariables } from "../../../../lib/graphql/mutation/Job/__generated__/JobCard";
import { SITE_JOBS } from "../../../../lib/graphql/query/Job";
import { SiteJobs, SiteJobsVariables } from "../../../../lib/graphql/query/Job/__generated__/SiteJobs";
import { Site } from "../../../../lib/graphql/query/Site/__generated__/Site";
import { Viewer } from "../../../../lib/types";
import { displaySuccessNotification } from "../../../../lib/utils";
import { jobsStyles } from "./styles";

interface Props {
  siteData: Site | undefined
  viewer: Viewer
}

const {Content} = Layout;
const {Title} = Typography;

export const Jobs:React.FC<Props> = ({siteData, viewer}) => {
  const [sort, setSort] = useState({key: "plannedStart", order: "desc"})
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const {data, loading, refetch} = useQuery<SiteJobs, SiteJobsVariables>(SITE_JOBS, {
    variables: {
      id: siteData?.site.id as string,
      limit,
      page,
      sort,
    }
  })
  const [getJobCard, {loading: fileLoading}] = useMutation<JobCard, JobCardVariables>(JOB_CARD, {
    onCompleted: data => {
      if(data && data.jobCard) {
        let fetchDataModified = `data:application/pdf;base64,${data.jobCard}`
        let a = document.createElement("a")
        a.href = fetchDataModified
        a.download = `contego-jobcard-${dayjs().format("DD-MM-YYYY")}.pdf`
        a.click()
      }
    }
  });
  const [deleteJobWithId] = useMutation<DeleteJob, DeleteJobVariables>(DELETE_JOB, {
    onCompleted: data => {
      if(data && data.deleteJob) {
        setPage(1);
        displaySuccessNotification("Job deleted successfully")
        refetch();
      }
    },
    onError: err => {
      displaySuccessNotification('Error deleting job')
    }
  })

  const onJobCardFetch = (id: string) => {
    getJobCard({
      variables: {
        id
      }
    });
  }

  const deleteJob = (id: string) => {
    deleteJobWithId({
      variables: {
        id
      }
    })
  }

  const columns = [
    {
      title: "Reference",
      dataIndex: "ref",
      key: "ref",
    },
    {
      title: "Start Date",
      dataIndex: "plannedStart",
      key: "plannedStart",
      sorter: true,
      render: (plannedStart: Date) => dayjs(plannedStart).format("DD-MM-YYYY"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Technician",
      dataIndex: "resourceName",
      key: "resourceName",
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      key: "status",
    },
    {
      title: "Pests Found",
      dataIndex: "pestRisk",
      sorter: true,
      key: "pestRisk",
    },
    {
      title: "Completed On",
      dataIndex: "realEnd",
      key: "realEnd",
      sorter: true,
      render: (realEnd: Date) => dayjs(realEnd).isAfter(dayjs("2000-01-01")) && dayjs(realEnd).format("DD-MM-YYYY"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (item: any) => (
        <>
          {
            item.status.includes("Complete") && <Button type="link" loading={fileLoading} onClick={() => onJobCardFetch(item.id)}>Report</Button>
          }
          {
            viewer.userRole?.includes('ADMIN') && !item.status.includes("Complete") && <Popconfirm title="Are you sure? This is irreversible!" onConfirm={() => deleteJob(item.id)}><Button type='link' className={styles.deleteButton}>Delete</Button></Popconfirm>
          }
        </>
        )
    },
  ]

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSort({
      key: sorter.columnKey,
      order: sorter.order === "ascend" ? "asc" : "desc"
    })
  }
  const styles = jobsStyles();

  return (
    <Content className={styles.container}>
      <Card>
        <Title>Jobs</Title>
        <Table 
        dataSource={data?.siteJobs.result} 
        columns={columns}
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          total: data?.siteJobs.total,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        loading={loading}
      />
      </Card>
    </Content>
  )
}