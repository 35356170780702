import { createUseStyles } from "react-jss";
import { spacing } from "../../../../styles";

export const sitePlansStyles = createUseStyles({
  container: {
    marginTop: spacing.medium
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    "& *": {
      marginBottom: "0 !important",
      marginRight: spacing.small
    }
  }
})