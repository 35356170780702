import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Input, Layout, Popconfirm, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ESCALATE_SITE } from "../../lib/graphql/mutation/Site";
import { EscalateSite, EscalateSiteVariables } from "../../lib/graphql/mutation/Site/__generated__/EscalateSite";
import { SITES } from "../../lib/graphql/query/Site";
import { Sites as SitesData, SitesVariables } from "../../lib/graphql/query/Site/__generated__/Sites";
import { Viewer } from "../../lib/types";
import { displayErrorMessage, displaySuccessNotification } from "../../lib/utils";
import { colors } from "../../styles";
import { sitesStyles } from "./styles";

interface Props {
  initialLimit?: number
  viewer?: Viewer
}

const {Content} = Layout;
const {Title} = Typography;

const displayRiskPill = (risk: string) => {
  switch(risk) {
    case "VERYHIGH":
      return <Tag color={colors.black}>Very High</Tag>
    case "HIGH":
      return <Tag color={colors.red}>High</Tag>
    case "MEDIUM":
      return <Tag color={colors.orange}>Medium</Tag>
    case "LOW":
      return <Tag color={colors.green}>Low</Tag>
  }
}

export const Sites: React.FC<Props> = ({initialLimit = 20, viewer}) => {
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(initialLimit)
  const [lookup, setLookup] = useState("")
  const [sort, setSort] = useState({key: "name", order: "asc"})
  const [filter, setFilter] = useState({key: "", value: ""})
  const styles = sitesStyles();
  const {data, loading, refetch} = useQuery<SitesData, SitesVariables>(SITES, {
    variables: {
      page,
      limit,
      lookup,
      sort,
      filter
    }
  })
  const [escalateSite] = useMutation<EscalateSite, EscalateSiteVariables>(ESCALATE_SITE, {
    onCompleted: data => {
      if(data && data.escalateSite) {
        displaySuccessNotification("Sites has been successfully escalated/de-escalated");
        refetch();
      }
    },
    onError: error => {
      if(error) {
        displayErrorMessage("Something went wrong, please try again later.")
      }
    }
  });

  let [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const riskRating = searchParams.get("riskRating");
    if(riskRating) {
      setFilter({key: "riskRating", value: riskRating?.toUpperCase()})
    }

    const inEscalation = searchParams.get("inEscalation");
    if(inEscalation) {
      setSort({key: "inEscalation", order: "desc"})
    }
  }, [searchParams])


  const handleEscalation = (id: string, isEscalated: boolean) => {
    escalateSite({
      variables: {
        id,
        isEscalated
      }
    })
  }

  const columns = [
    {
      title: "Site Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "In Escalation",
      dataIndex: "inEscalation",
      key: "inEscalation",
      sorter: true,
      render: (inEscalation: boolean) => inEscalation ? <Tag color={colors.red}>In Escalation</Tag> : null
    },
    {
      title: "Street",
      dataIndex: "street",
      key: "street"
    },
    {
      title: "Postcode",
      dataIndex: "postcode",
      key: "postcode"
    },
    {
      title: "Risk Rating",
      dataIndex: "ratings",
      key: "ratings",
      filters: [
        {
          text: 'Very High',
          value: 'VERYHIGH',
        },
        {
          text: 'High',
          value: 'HIGH',
        },
        {
          text: 'Medium',
          value: 'MEDIUM',
        },
        {
          text: 'Low',
          value: 'LOW',
        },
      ],
      filterMultiple: false,
      render: (rating: any) => (<>{rating[0] ? displayRiskPill(rating[0].riskRating) : ""}</>)
    },
    {
      title: "Pests Found",
      dataIndex: "ratings",
      key: "pests",
      render: (rating: any) => (<>{rating[0] ? rating[0].pestRisk.length ? rating[0].pestRisk : ""  : "" }</>)
    },
    // {
    //   title: "Urgent Actions",
    //   dataIndex: "_count",
    //   key: "actions",
    //   render: (siteCount: any) => (<>{siteCount.actions > 0 ? `${siteCount.actions} remaining` : "No actions"}</>)
    // },
    {
      title: "Actions",
      key: "manage",
      render: (item: any) => (
      <><Link to={`/site/${item.id}`}><Button type="link">Manage</Button></Link>
        {
          viewer && (viewer?.userRole?.includes("ADMIN") || viewer?.userRole?.includes("ACCOUNTMANAGER")) ? (
            item.inEscalation ? (
              <Popconfirm
                title="Are you sure you want to de escalate this site?"
                okText="Confirm"
                onConfirm={() => {
                  handleEscalation(item.id, false);
                }}
              >
                <Button type='link'><span style={{color: colors.green}}>De Escalate</span></Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Are you sure you want to escalate this site?"
                okText="Confirm"
                onConfirm={() => {
                  handleEscalation(item.id, true);
                }}
              >
                <Button type='link'><span style={{color: colors.red}}>Escalate</span></Button>
              </Popconfirm>
            )
           ) : null
        }
      </>
      )
    },
  ]

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleLookupChange = (evt: any) => {
		setLookup(evt.target.value);
	};

  const handleTableChange = (_pagination: any, filters: any, sorter: any) => {
    setSort({
      key: sorter.columnKey,
      order: sorter.order === "ascend" ? "asc" : "desc"
    })
    setFilter(
      {
        key: 'riskRating',
        value: filters.ratings[0]
      }
    )
  }

  return (
    <Content className={styles.container}>
      <Card>
        <Title>Your Sites</Title>
        <Input className={styles.searchInput} placeholder="Search" onChange={evt => handleLookupChange(evt)} />
        <Table 
          className={styles.table}
          dataSource={data?.sites.result as []} 
          columns={columns} 
          loading={loading}
          pagination={{
						defaultPageSize: limit,
						defaultCurrent: page,
						total: data?.sites.total,
						onChange: handlePaginationChange,
					}}
          rowKey="email"
          onChange={handleTableChange}
        />
      </Card>
    </Content>
  )
}