import { Breadcrumb, Button, Menu, PageHeader, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LoadingPage, RequestQuote, RequestVisit, UpdateSiteAutoCloseActions, UpdateSiteRatingModal } from '../../../../lib/components';
import { UserRole } from '../../../../lib/graphql/globalTypes';
import { Site } from '../../../../lib/graphql/query/Site/__generated__/Site';
import { Viewer } from '../../../../lib/types';
import { headerStyles } from './styles';

interface Props {
  siteData: Site | undefined
  viewer: Viewer
}

const { Text } = Typography;
const { Item } = Menu;

export const Header: React.FC<Props> = ({ siteData, viewer }) => {
  const styles = headerStyles();
  const [currentPage, setCurrentPage] = useState("summary")
  const [siteRatingModal, setSiteRatingModal] = useState(false);
  const [autoCloseSiteActionsModal, setAutoCloseSiteActionsModal] = useState(false);

  const [quoteModalVisible, setQuoteModalVisible] = useState<boolean>(false)
  const [visitModalVisible, setVisitModalVisible] = useState<boolean>(false)

  const handlePageChange = (e: { key: string }) => {
    setCurrentPage(e.key)
  }

  if (!siteData) {
    return (
      <LoadingPage />
    )
  }

  const headerContent = (
    <div className={styles.headerContent}>
      <div className={styles.info}>
        <Text className={styles.infoLabel}>Next Visit</Text>
        <Text>{siteData.site.nextVisit === -1 ? "No scheduled visits" : (siteData.site.nextVisit as number + 1) + " Day(s)"}</Text>
      </div>
      <div className={styles.info}>
        {
          siteData.site.nextTechnician && (
            <>
              <Text className={styles.infoLabel}>Technician</Text>
              <Text>{siteData.site.previousTechnician}</Text>
            </>
          )
        }

      </div>
      <Button className={styles.quoteButton + " " + styles.infoButton} type='default' onClick={() => setQuoteModalVisible(!quoteModalVisible)}>Request A Quote</Button>
      <Button className={styles.infoButton} type='primary' onClick={() => setVisitModalVisible(!visitModalVisible)}>Request A Visit</Button>
    </div>
  )

  return (
    <>
      <Breadcrumb className={styles.breadcrumbContainer}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Sites</Breadcrumb.Item>
        <Breadcrumb.Item>{siteData.site.name}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        className={styles.container}
        onBack={() => window.history.back()}
        title={siteData.site.name}
        subTitle={` - ${siteData.site.street}`}
        extra={[
          headerContent
        ]}
      >
        <Menu className={styles.menu} mode="horizontal" selectedKeys={[currentPage]} onClick={handlePageChange}>
          <Item key="summary">
            <Link to="">
              <Text>Summary</Text>
            </Link>
          </Item>
          <Item key="sites">
            <Link to="jobs">
              <Text>Jobs & Reports</Text>
            </Link>
          </Item>
          <Item key="actions">
            <Link to="actions">
              <Text>Actions</Text>
            </Link>
          </Item>
          <Item key="files">
            <Link to="files">
              <Text>Files</Text>
            </Link>
          </Item>
          <Item key="site-plans">
            <Link to="site-plans">
              <Text>Site Plans</Text>
            </Link>
          </Item>
          <Item key="reports">
            <Link to="reports">
              <Text>Generate A Report</Text>
            </Link>
          </Item>
          {
            (viewer.userRole === UserRole.ADMIN || viewer.userRole === UserRole.SUPERADMIN || viewer.userRole === UserRole.ACCOUNTMANAGER) && (
              <>
                <Item key="autoCloseActions">
                  <Button type='link' className={styles.updateRiskButton} onClick={() => setAutoCloseSiteActionsModal(true)}>Auto Close Actions</Button>
                </Item>
                <Item key="updateSiteRating">
                  <Button type='link' className={styles.updateRiskButton} onClick={() => setSiteRatingModal(true)}>Update Site Rating</Button>
                </Item>
              </>

            )
          }
        </Menu>
      </PageHeader>
      <RequestQuote visible={quoteModalVisible} setVisible={setQuoteModalVisible} siteName={siteData.site.name} />
      <RequestVisit visible={visitModalVisible} setVisible={setVisitModalVisible} siteName={siteData.site.name} />
      <UpdateSiteRatingModal visible={siteRatingModal} onClose={() => setSiteRatingModal(false)} currentRating={siteData.site.ratings ? siteData.site.ratings[0]?.riskRating : "not set"} siteId={siteData.site?.id} />
      <UpdateSiteAutoCloseActions visible={autoCloseSiteActionsModal} onClose={() => setAutoCloseSiteActionsModal(false)} currentAutoCloseSetting={Boolean(siteData.site.autoCloseActions)} siteId={siteData.site?.id} />
    </>
  )
}