import { useQuery } from '@apollo/react-hooks';
import { Button, Card, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { UpdateAction } from '../../../../../../lib/components';
import { SITE_ACTIONS } from '../../../../../../lib/graphql/query/Action';
import { SiteActions, SiteActionsVariables } from '../../../../../../lib/graphql/query/Action/__generated__/SiteActions';
import { sentenceCase } from '../../../../../../lib/utils';
import { base, colors } from '../../../../../../styles';
import { urgentActionsTableStyles } from './styles';

interface Props {
  siteId: string | undefined
}

const {Title} = Typography;

export const UrgentActionsTable: React.FC<Props> = ({siteId}) => {
  const [selectedAction, setSelectedAction] = useState<null | string>(null)

  const [sort, setSort] = useState({key: "date", order: "desc"})
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const {data, loading} = useQuery<SiteActions, SiteActionsVariables>(SITE_ACTIONS, {
    variables: {
      id: siteId as string,
      limit,
      page,
      sort,
      filter: "Incomplete"
    }
  })

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: base(7.5),
      sorter: true,
      render: (date: Date) => dayjs(date).format("DD-MM-YYYY"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: base(7.5),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description"
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
      width: base(7.5),
      render: (assignedTo: string) => sentenceCase(assignedTo)
    },
    {
      title: "Risk Rating",
      dataIndex: "riskRating",
      key: "riskRating",
      render: (riskRating: string) => {
        switch(riskRating) {
          case "VERYHIGH":
            return <Tag color={colors.black}>Very High</Tag>
          case "HIGH":
            return <Tag color={colors.red}>High</Tag>
          case "MEDIUM":
            return <Tag color={colors.orange}>Medium</Tag>
          case "LOW":
            return <Tag color={colors.green}>Low</Tag>
          default:
            return <Tag color={colors.green}>Low</Tag>
        }
      }
    },
    {
      title: "Update",
      key: "update",
      render: (item: any) => (
          item.assignedTo === "CUSTOMER" && <Button type="link" onClick={() => {
            setSelectedAction(item.id);
          }} >Update</Button>
        )
    }
  ]

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSort({
      key: sorter.columnKey,
      order: sorter.order === "ascend" ? "asc" : "desc"
    })
  }

  const styles = urgentActionsTableStyles();
  return (
    <Card className={styles.container}>
      <Title level={2}>Urgent Actions</Title>
      <Table 
        dataSource={data?.siteActions.result} 
        columns={columns}
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          total: data?.siteActions.total,
          onChange: handlePaginationChange,
        }}
        onChange={handleTableChange}
        loading={loading}
      />
      <UpdateAction setModalVisible={setSelectedAction} actionId={selectedAction} />
    </Card>
  )
}