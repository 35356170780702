import { createUseStyles } from 'react-jss';
import { base, colors, queries, spacing } from '../../../../styles';

export const headerStyles = createUseStyles({
  container: {
    "& .ant-page-header-heading-title": {
      fontSize: base(2) + "!important"
    },
    "& *": {
      color: colors.white
    },
    "& .anticon-arrow-left": {
      fontSize: base(2) + "!important",
    },
    padding: spacing.small,
    paddingBottom: "10px",
    backgroundColor: colors.black,
    borderBottom: `1px solid ${colors.light}`,
    textTransform: "uppercase"
  },
  breadcrumbContainer: {
    paddingLeft: spacing.small,
    backgroundColor: colors.black,
    "& *": {
      color: colors.white 
    },
    [queries.m]: {
      display: "none"
    },

    '& > ol': {
      display: 'flex',
      listStyle: 'none',
    }
  },
  headerContent: {
    borderLeft: `1px solid ${colors.gray}`,
    paddingLeft: spacing.xSmall,
    display: 'flex',
    flexDirection: "row",
  },
  info: {
    display: 'flex',
    flexDirection: "column",
    paddingLeft: spacing.xSmall,
    paddingRight: spacing.medium,
    justifyContent: "center",
    alignItems: "center",
  },
  infoLabel: {
    fontSize: "15px !important",
    fontWeight: 600 + "!important",
    lineHeight: base(1)
  },
  infoButton: {
    height: "50px",
    marginRight: spacing.small,
    "& span": {
      color: colors.black + "!important"
    },
    [queries.l]: {
      display: "none"
    }
  },
  quoteButton: {
    background: colors.white,
    [queries.l]: {
      display: "none"
    }
  },
  menu: {
    background: colors.black,
    "& span": {
      fontSize: base(0.75) + "!important",
      color: colors.white,
    },
    borderBottom: "none" ,
    marginBottom: spacing.small
  },
  updateRiskButton: {
    padding: '0 !important',
    margin: '0 !important',
    '& span': {
      textTransform: 'uppercase',
    }
  }
})