import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Input, Layout, Popconfirm, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { UserRole } from "../../lib/graphql/globalTypes";
import { ARCHIVE_USER } from "../../lib/graphql/mutation/User";
import { ArchiveUser, ArchiveUserVariables } from "../../lib/graphql/mutation/User/__generated__/ArchiveUser";
import { USERS } from "../../lib/graphql/query/Users";
import { Users as UsersData, UsersVariables } from "../../lib/graphql/query/Users/__generated__/Users";
import { Viewer } from "../../lib/types";
import { displayErrorMessage, displaySuccessNotification, sentenceCase } from "../../lib/utils";
import { UsersStyles } from "./styles";

interface Props {
  viewer: Viewer
}

const {Content} = Layout;
const {Title, Text} = Typography;

export const Users: React.FC<Props> = ({viewer}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [lookup, setLookup] = useState<string>("")
  const {data, loading, refetch} = useQuery<UsersData, UsersVariables>(USERS, {
    variables: {
      limit,
      page,
      lookup
    }
  });
  const [archiveUser] = useMutation<ArchiveUser, ArchiveUserVariables>(ARCHIVE_USER, {
    onCompleted: () => {
      displaySuccessNotification("User has been archived")
    },
    onError: () => {
      displayErrorMessage("Something went wrong. Please try again later.")
    }
  })
  const styles = UsersStyles();

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName"
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Recieve Emails",
      dataIndex: "receiveEmailNotifications",
      key: "receiveEmailNotifications",
      render: (item: boolean) => (
        item ? "Yes" : "No"
      )
    },
    {
      title: "Locked Out",
      dataIndex: "lockout",
      key: "lockout",
      render: (item: boolean) => (
        item ? "Yes" : "No"
      )
    },
    {
      title: "Role",
      dataIndex: "userRole",
      key: "userRole",
      render: (role: UserRole) => sentenceCase(role)
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => {
        if(record.userRole !== "SUPERADMIN" || viewer.userRole === "SUPERADMIN")
        return (
          <Space size="middle">
            <NavLink to={`/users/edit/${record.id}`}>Edit</NavLink>
            <Popconfirm
              title="Are you sure you want to delete this user?"
              okText="Confirm"
              onConfirm={() => {
                handleArchiveUser(record.id);
              }}
            >
              <Button type='link'><Text type="danger">Delete</Text></Button>
            </Popconfirm>
          </Space>
        )
      }
        
    },
  ]

  const handleArchiveUser = (id: string) => {
    archiveUser({
      variables: {
        id
      }
    });
    refetch();
  }

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  const handleLookupChange = (evt: any) => {
		setLookup(evt.target.value);
	};

  return (
    <Content className={styles.containers}>
      <Card>
        <div className={styles.cardTitleContainer}>
          <Title level={1}>Users</Title>
          <Link to="/users/add">
            <Button href="/users/add" type="primary" className={styles.addButton}>Add User</Button>
          </Link>
        </div>
        <Input className={styles.searchInput} placeholder="Search" onChange={evt => handleLookupChange(evt)} />
        <Table 
          className={styles.table}
          dataSource={data?.users.result as []} 
          columns={columns} 
          loading={loading}
          pagination={{
						defaultPageSize: limit,
						defaultCurrent: page,
						total: data?.users.total,
						onChange: handlePaginationChange,
					}}
          rowKey="email"
        />
      </Card>
    </Content>
  )
}