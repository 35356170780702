import { Bar, BarConfig } from '@ant-design/plots';
import { Datum } from '@antv/g2plot';
import React from 'react';
import { base, colors } from '../../../../../../../../styles';

interface Props {
  buildingCondition: number | undefined
  pestActivity: number | undefined
  hygiene: number | undefined
  proofing: number | undefined
  observational: number | undefined
  service: number | undefined
}

export const RecommendationTypesPlot: React.FC<Props> = ({buildingCondition, pestActivity, hygiene, proofing, observational, service}) => {

  const data: Datum[] = [
    {
      rating: 'Building Condition',
      value: buildingCondition,
    },
    {
      rating: 'Pest Activity',
      value: pestActivity,
    },
    {
      rating: 'Hygiene',
      value: hygiene,
    },
    {
      rating: 'Proofing',
      value: proofing,
    },
    {
      rating: 'Observational',
      value: observational,
    },
    {
      rating: 'Service',
      value: service,
    }
  ];
  const config: BarConfig = {
    data,
    style: {
      height: base(10),
    },
    color: ({rating}: Datum) => {
      switch(rating) {
        // case "Very High":
        //   return colors.black
        // case "High":
        //   return colors.red
        // case "Medium":
        //   return colors.orange
        // case "Low":
        //   return colors.lowRating
        default:
          return colors.gray
      }
    },
    xField: 'value',
    yField: 'rating',
    seriesField: 'rating',
    legend: {
      position: 'top-left',
    },
  };
  return <Bar {...config} />;
}