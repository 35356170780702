import { gql } from "apollo-boost";

export const ADD_USER = gql`
  mutation AddUser($input: AddEditUserInput!) {
    addUser(input: $input) {
      id
    }
  }
`

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      id
    }
  }
`
export const UPDATE_USER = gql`
  mutation UpdateUser($input: AddEditUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`

export const ARCHIVE_USER = gql`
  mutation ArchiveUser($id: String!) {
    archiveUser(id: $id) {
      id
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String, $resetToken: String) {
    forgotPassword(email: $email, resetToken: $resetToken)
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($id: ID!) {
    resetPassword(id: $id) {
      id
    }
  }
`