import { createUseStyles } from "react-jss";
import { base, colors, queries, spacing } from "../../../styles";

export const AppHeaderStyles = createUseStyles({
  container: {
    background: colors.green,
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center"
  },
  text: {
    color: colors.white,
    fontSize: base(0.9),
    marginRight: spacing.xSmall,
    [queries.s]: {
      display: "none"
    }
  },
  logo: {
    fill: colors.white
  }
})