import { Pie, PieConfig } from '@ant-design/plots';
import { Datum } from '@antv/g2plot';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { base, colors } from '../../../../../../../../styles';

interface Props {
  veryHigh: number | undefined
  high: number | undefined
  medium: number | undefined
  low: number | undefined
}

export const ActionsDonutPlot: React.FC<Props> = ({veryHigh, high, medium, low}) => {
  const [selected, setSelected] = useState<null | string>(null)

  
  if(selected) {
    return <Navigate to={`actions?riskRating=${selected.replace(' ', '')}`} />
  }

  const data: Datum[] = [
    {
      type: 'Very High',
      value: veryHigh,
    },
    {
      type: 'High',
      value: high,
    },
    {
      type: 'Medium',
      value: medium,
    },
    {
      type: 'Low',
      value: low,
    }
  ];
  const config: PieConfig = {
    style: {
      height: base(10),
    },
    data,
    color: ({type}: Datum) => {
      switch(type) {
        case "Very High":
          return colors.black
        case "High":
          return colors.red
        case "Medium":
          return colors.orange
        case "Low":
          return colors.lowRating
        default:
          return colors.gray
      }
    },
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.5,
    interactions: [
      {
        type: "element-single-selected",
        cfg: {
          start: [{ trigger: 'element:click', action: (node: any) => {setSelected(node.event.data.data.type)} }],
        }
        
      }
    ]
  };
  return <Pie {...config} />;
}