import { Card, Col, Layout, Row, Typography } from "antd";
import React, { useState } from "react";
import { ActiveContactsTable, ContactsTable } from "./components";
import { contactStyles } from "./styles";

const {Content} = Layout;
const {Title} = Typography;

export const Contacts: React.FC = () => {
  const [refresh, setRefresh] = useState(0);
  const styles = contactStyles();

  return (
    <Content className={styles.container}>
      <Row gutter={36}>
        <Col md={12} xs={24}>
          <Card>
            <Title>Contacts</Title>
            <ContactsTable refresh={refresh} setRefresh={setRefresh} />
          </Card>
        </Col>
        <Col md={12} xs={24}>
          <Card>
            <Title>Active Contacts</Title>
            <ActiveContactsTable refresh={refresh} setRefresh={setRefresh} />
          </Card>
        </Col>
      </Row>
    </Content>
  )
}