import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input, Modal, Select, Typography } from "antd";
import { REQUEST_QUOTE } from "../../graphql/mutation/UserRequest";
import { RequestQuote as RequestQuoteData, RequestQuoteVariables } from "../../graphql/mutation/UserRequest/__generated__/RequestQuote";
import { displayErrorMessage, displaySuccessNotification } from "../../utils";

interface Props {
  visible: boolean
  setVisible: (state: boolean) => void
  siteName: string
}

const {Title} = Typography;
const {Item} = Form;
const {TextArea} = Input;
const {Option} = Select

export const RequestQuote: React.FC<Props> = ({visible, setVisible, siteName}) => {
  const [requestQuote] = useMutation<RequestQuoteData, RequestQuoteVariables>(REQUEST_QUOTE, {
    onCompleted: (data) => {
      if(data && data.requestQuote) {
        displaySuccessNotification("Quote request sent")
      }  
    },
    onError: (error) => {
      if(error) {
        displayErrorMessage("Something went wrong, please try again later")
      }  
    }
  })

  const handleFormSubmit = (input: any) => {
    requestQuote({
      variables: {
        input: {
          ...input,
          siteName: siteName === "N/A" ? input.siteName : siteName
        }
      }
    });
    setVisible(false)
  }

  return (
    <Modal 
      visible={visible}
      onCancel={() => setVisible(false)}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <Title level={2}>Request a Quote</Title>
      <Form layout="vertical" onFinish={handleFormSubmit}>
        <Item name="name" label="Name" rules={[{required: true}]}>
          <Input />
        </Item>
        {
          siteName === "N/A" && (
            <>
              <Item name="siteName" label="SiteName" rules={[{required: true}]}>
                <Input />
              </Item>
              <Item name="postCode" label="Post Code" rules={[{required: true}]}>
                <Input />
              </Item>
              <Item name="phoneNumber" label="Phone Number" rules={[{required: true}]}>
                <Input />
              </Item>
            </>
          )
        }
        <Item name="details" label="Details" rules={[{required: true, message: 'Details are required'}]}>
          <TextArea />
        </Item>
        <Item name="type" label="Type of consultation" rules={[{required: true, message: 'Please select virtual or onsite consultation'}]}>
          <Select>
            <Option value="Virtual Consultation">Virtual Consultation</Option>
            <Option value="Onsite Consultation">Onsite Consultation</Option>
          </Select>
        </Item>
        <Item name="submit">
          <Button type="primary" htmlType="submit">Submit</Button>
        </Item>
      </Form>
    </Modal>
  )
}