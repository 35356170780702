import { gql } from "apollo-boost";

export const QUOTES = gql`
  query Quotes($limit: Int!, $page: Int!, $sort: SortField) {
    quotes(limit: $limit, page: $page, sort: $sort) {
      total
      result {
        id
        price
        status
        note
        reference
        site {
          id
          name
          street
        }
        createdAt
      }
    }
  }
`

export const QUOTE = gql`
  query Quote($id: String!) {
    quote(id: $id) {
      id
      price
      status
      note
      reference
      site {
        id
        name
        street
      }
      createdAt
    }
  }
`