import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Form, Input, Layout, Typography } from "antd";
import { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../lib/graphql/mutation/User";
import { ForgotPassword as ForgotPasswordData, ForgotPasswordVariables } from "../../lib/graphql/mutation/User/__generated__/ForgotPassword";
import { displaySuccessNotification } from "../../lib/utils";
import { base } from "../../styles";
import { ReactComponent as Logo } from './../../assets/logo.svg';
import { forgotPasswordStyles } from "./styles";

const {Content} = Layout;
const {Title} = Typography;

export const ForgotPassword: React.FC = () => {
  const [forgotPassword, {loading}] = useMutation<ForgotPasswordData, ForgotPasswordVariables>(FORGOT_PASSWORD);
  const forgotPasswordRef = useRef(forgotPassword)
  const {token} = useParams()
  const styles = forgotPasswordStyles();

  const handleSubmit = (input: any) => {
    forgotPassword({
      variables: {
        email: input.email
      }
    })
    displaySuccessNotification("Please check your inbox for further instructions")
  }

  useEffect(() => {
    if(token) {
      forgotPasswordRef.current({
        variables: {
          resetToken: token
        }
      })
    }
  }, [forgotPasswordRef, token])

  return (
    <Content className={styles.container}>
      <Card>
        <div className={styles.logoContainer}>
          <Logo width={base(10)} className={styles.logo} />
        </div>
        <Title>Forgot your password</Title>
        {token ? (
          <>  
            <p>Password Reset successfully, please check your inbox</p>
            <Link to="/">
              <Button type="primary">Go To Login</Button>
            </Link>
          </>
        ): (
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item label="Your email" name="email" rules={[{required: true, message: 'Your email is required', type: 'email'}]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">Send</Button>
            </Form.Item>
          </Form>
        )}
        
      </Card>
    </Content>
  )
}