import { gql } from "apollo-boost";

export const ACTIONS_SUMMARY = gql`
  query ActionsSummary($id: String) {
    actionsSummary(id: $id) {
      veryHighRisk
      highRisk
      mediumRisk
      lowRisk
      customerActions
      contegoActions
    }
  }
`

export const SITES_SUMMARY = gql`
  query SitesSummary($id: String) {
    sitesSummary(id: $id) {
      veryHighRisk
      highRisk
      mediumRisk
      lowRisk
      sitesAtRisk
      rats
      mice
      avian
      wild
      flyingInsects
      insects
      contactName
    }
  }
`