import { createUseStyles } from "react-jss";
import { base, spacing } from "../../styles";

export const UsersStyles = createUseStyles({
  containers: {
    marginTop: spacing.medium
  },
  cardTitleContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  addButton: {
    height: base(2)
  },
  searchInput: {
    margin: "20px 0",
    maxWidth: base(15),
    float: "right"
  },
  table: {
    overflowX: "auto"
  }
})