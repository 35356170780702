import { gql } from "apollo-boost";

export const ADD_QUOTE = gql`
  mutation AddQuote ($input: AddQuoteInput!) {
    addQuote (input: $input) {
      id
      siteId
      reference
      blobName
      status
      createdAt
      updatedAt
    }
  }
`
export const UPDATE_QUOTE = gql`
  mutation UpdateQuote ($input: UpdateQuoteInput!) {
    updateQuote (input: $input) {
      id
    }
  }
`