/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionOwner {
  CONTEGO = "CONTEGO",
  CUSTOMER = "CUSTOMER",
}

export enum AdvancedReportOptions {
  ACTIONS = "ACTIONS",
  AUDITS = "AUDITS",
  CALLOUTS_OVERVIEW = "CALLOUTS_OVERVIEW",
  CALL_OUTS = "CALL_OUTS",
  CURRENT = "CURRENT",
  ESCALATION_REPORT = "ESCALATION_REPORT",
  JOBS = "JOBS",
  OVERVIEW = "OVERVIEW",
  PEST_BY_AREA = "PEST_BY_AREA",
  RATINGS = "RATINGS",
  ROUTINES = "ROUTINES",
  TOP_TEN_SITES = "TOP_TEN_SITES",
  WEEKLY_RATINGS = "WEEKLY_RATINGS",
}

export enum QuoteStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

export enum Rating {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  NONE = "NONE",
  VERYHIGH = "VERYHIGH",
}

export enum UserRole {
  ACCOUNTMANAGER = "ACCOUNTMANAGER",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SUPERADMIN = "SUPERADMIN",
}

export interface AddComplianceFileInput {
  file: string;
  fileName: string;
  type: string;
  fileSize: number;
}

export interface AddEditUserInput {
  id?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  receiveEmailNotifications: boolean;
  phoneNumber?: string | null;
  sites?: string[] | null;
  password?: string | null;
  userRole?: string | null;
  confirmPassword?: string | null;
}

export interface AddQuoteInput {
  siteId: string;
  reference?: string | null;
  file: string;
  price?: number | null;
  note?: string | null;
}

export interface AddSiteFileInput {
  id: string;
  file: string;
  fileName: string;
  type: string;
  fileSize: number;
}

export interface AddSitePlanInput {
  id: string;
  file: string;
  fileName: string;
  type: string;
  fileSize: number;
}

export interface AdvancedReportInput {
  id: string;
  startDate: any;
  endDate: any;
  options?: AdvancedReportOptions[] | null;
}

export interface CloseActionsInput {
  actionOwner: string;
  siteId: string;
  startDate: any;
  endDate: any;
  closeNotes?: string | null;
}

export interface FilterField {
  key?: string | null;
  value?: string | null;
}

export interface GenerateReportInput {
  id: string;
  startDate: any;
  endDate: any;
}

export interface LogInInput {
  email: string;
  password: string;
}

export interface RequestQuoteInput {
  name?: string | null;
  details: string;
  type: string;
  siteName: string;
  postCode?: string | null;
  phoneNumber?: string | null;
}

export interface RequestVisitInput {
  name?: string | null;
  details: string;
  priority: boolean;
  siteName: string;
  postCode?: string | null;
  phoneNumber?: string | null;
}

export interface SortField {
  key?: string | null;
  order?: string | null;
}

export interface UpdatePasswordInput {
  password: string;
  confirmPassword: string;
}

export interface UpdateQuoteInput {
  id: string;
  status: QuoteStatus;
  purchaseOrder?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
