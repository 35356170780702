import { gql } from "apollo-boost";

export const SITE_JOBS = gql`
  query SiteJobs($id: String!, $limit: Int!, $page: Int!, $sort: SortField, $filterOut: String) {
    siteJobs(id: $id, limit: $limit, page: $page, sort: $sort, filterOut: $filterOut) {
      total
      result {
        id
        type
        resourceName
        plannedStart
        realEnd
        status
        created
        pestRisk
        ref
      }
    }
  }
`