import { gql } from "apollo-boost";

export const GENERATE_REPORT = gql`
  mutation GenerateReport($input: GenerateReportInput!) {
    generateReport(input: $input)
  }
`

export const GENERATE_SITE_REPORT = gql`
  mutation GenerateSiteReport($input: GenerateReportInput!) {
    generateSiteReport(input: $input)
  }
`
export const GENERATE_ADVANCED_REPORT = gql`
  mutation GenerateAdvancedReport($input: AdvancedReportInput!) {
    generateAdvancedReport(input: $input)
  }
`