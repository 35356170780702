import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, Col, Form, Input, Layout, Row, Select, Space, Switch, Tree, Typography } from "antd";
import { DataNode } from "antd/lib/tree";
import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ADD_USER, RESET_PASSWORD, UPDATE_USER } from "../../lib/graphql/mutation/User";
import { AddUser, AddUserVariables } from "../../lib/graphql/mutation/User/__generated__/AddUser";
import { ResetPassword, ResetPasswordVariables } from "../../lib/graphql/mutation/User/__generated__/ResetPassword";
import { UpdateUser, UpdateUserVariables } from "../../lib/graphql/mutation/User/__generated__/UpdateUser";
import { CONTACTS } from "../../lib/graphql/query/Contact";
import { Contacts as ContactsData, ContactsVariables } from "../../lib/graphql/query/Contact/__generated__/Contacts";
import { USER } from "../../lib/graphql/query/Users";
import { User as UserData, UserVariables } from "../../lib/graphql/query/Users/__generated__/User";
import { Viewer } from "../../lib/types";
import { displayErrorMessage, displaySuccessNotification } from "../../lib/utils";
import { colors, spacing } from "../../styles";
import { addEditUser } from "./styles";

interface Props {
  viewer: Viewer
}

const { Title } = Typography
const { Content } = Layout
const { Option } = Select;
const { Item } = Form

export const AddEditUser: React.FC<Props> = ({ viewer }) => {
  const { userId } = useParams();
  const styles = addEditUser();
  const [lookup, setLookup] = useState("")
  const [treeData, setTreeData] = useState<undefined | DataNode[]>()
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [changePassword, setChangePassword] = useState(false);
  const { loading } = useQuery<ContactsData, ContactsVariables>(CONTACTS, {
    variables: {
      active: true,
      limit: 200,
      page: 1,
      lookup,
      includeSites: true,
    },
    onCompleted: data => {
      const contactsData = []
      if (data.contacts.result) {
        for (const contact of data.contacts.result) {
          if (contact.site) {
            let sites = [];
            if (contact && contact.sites) {
              contact.sites.sort((a, b) => {
                if (a.name < b.name) {
                  return -1
                }
                if (a.name > b.name) {
                  return 1
                }
                return 0
              });
              for (const site of contact.sites) {
                sites.push({
                  key: site.id,
                  title: site.name,
                  value: site.id
                })
              }
            }
            contactsData.push({
              key: contact.site.id,
              value: contact.site.id,
              title: contact.site.name,
              children: sites
            })
          }
        }
      }

      setTreeData(contactsData);
    }
  })
  const [addUser, { data: userAddData, loading: submitLoading }] = useMutation<AddUser, AddUserVariables>(ADD_USER, {
    onCompleted: () => {
      displaySuccessNotification("User successfully created, refresh to see user in list")
    }
  });
  const { data: userData, loading: userLoading, refetch: userRefetch } = useQuery<UserData, UserVariables>(USER, {
    variables: {
      id: userId || "0"
    },
    onCompleted: (data) => {
      const siteIds = [];
      if (data.user.userSiteClaims) {
        for (const claim of data.user.userSiteClaims) {
          siteIds.push(claim.siteId)
        }
        setCheckedKeys(siteIds)
      }
    }
  });
  const [updateUser, { data: updateUserData }] = useMutation<UpdateUser, UpdateUserVariables>(UPDATE_USER, {
    onCompleted: () => {
      displaySuccessNotification("User updated successfully")
      userRefetch();
    }
  })

  const [resetPassword] = useMutation<ResetPassword, ResetPasswordVariables>(RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data.resetPassword) {
        displaySuccessNotification("Password reset successfully")
      }
    },
    onError: (error) => {
      if (error) {
        displayErrorMessage(error.message)
      }
    }
  })


  const handleSubmit = (input: any) => {
    if (userId) {
      updateUser({
        variables: {
          input: {
            ...input,
            sites: checkedKeys,
            id: userId
          }
        }
      });
    } else {
      addUser({
        variables: {
          input: {
            ...input,
            sites: checkedKeys
          }
        }
      });
    }
  }

  if (userAddData || updateUserData) {
    return <Navigate to="/users" />
  }


  const handleLookupChange = (evt: any) => {
    setLookup(evt.target.value);
  };

  const onCheck = (checkedKeysValue: any) => {
    setCheckedKeys(checkedKeysValue);
  };

  if (!userData || !userData.user) {
    if (userId) {
      return null
    }
  }

  return (
    <Content className={styles.container}>
      <Form layout='vertical' onFinish={handleSubmit} initialValues={userData?.user}>
        <Row gutter={36}>
          <Col md={12} xs={24}>
            <Card size="default">
              <Title>{userId ? "Edit" : "Add"} User</Title>
              <Row gutter={24}>
                <Col md={12} xs={24}>
                  <Item label="First Name" name="firstName" rules={[{ required: true, message: "Please input a first name" }]}>
                    <Input />
                  </Item>
                </Col>
                <Col md={12} xs={24}>
                  <Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please input a last name" }]}>
                    <Input />
                  </Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col md={18} xs={24}>
                  <Item label="Email Address" name="email" rules={[{ required: true, message: 'Your email is required', type: 'email' }]}>
                    <Input placeholder="john.doe@example.com" />
                  </Item>
                </Col>
                <Col md={6} xs={24}>
                  <Item label="Receive email notifications" name="receiveEmailNotifications" initialValue={userData ? Boolean(userData?.user.receiveEmailNotifications) : true}>
                    <Switch checked={userData ? Boolean(userData?.user.receiveEmailNotifications) : true} />
                  </Item>
                </Col>
              </Row>
              <Item label="Phone Number" name="phoneNumber">
                <Input addonBefore={"+44"} />
              </Item>
              {
                viewer.userRole === "SUPERADMIN" && (
                  <Item label="User Role" name="userRole">
                    <Select defaultValue="MANAGER">
                      <Option value="SUPERADMIN">Super Admin (Can create admins)</Option>
                      <Option value="ADMIN">Admin (Can see all data)</Option>
                      <Option value="ACCOUNTMANAGER">Account Manager (For contego employees only)</Option>
                      <Option value="MANAGER">Manager (Use for all customers)</Option>
                    </Select>
                  </Item>
                )
              }
              {
                userId && (
                  <>
                    {
                      changePassword ? (
                        <Row gutter={24}>
                          <Col md={12} xs={24}>
                            <Item label="Password" name="password" rules={[{
                              pattern: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,128}$/gm,
                              message: 'Password requires a minimum eight characters, at least one uppercase, one lowercase and one number',
                            }]}>
                              <Input.Password placeholder="**********" />
                            </Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <Item label="Confirm Password" name="confirmPassword">
                              <Input.Password placeholder="**********" />
                            </Item>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Button type='default' onClick={() => setChangePassword(true)} style={{ marginBottom: spacing.small, marginRight: spacing.small }}>Change Password</Button>
                          <Button onClick={() => {
                            resetPassword({
                              variables: {
                                id: userId
                              }
                            })
                          }}>Reset Password</Button>
                        </>
                      )
                    }
                  </>
                )
              }
              <Item>
                <Button type="primary" htmlType="submit" loading={submitLoading} >{userId ? "Update" : "Invite"} User</Button>
              </Item>
            </Card>
          </Col>
          <Col md={12} xs={24}>
            <Card size="default">
              <Title>Assign Sites</Title>
              {
                viewer.userRole === "SUPERADMIN" && (
                  <p>NOTICE: If you are adding an admin please feel free to leave these unselected!</p>
                )
              }
              <Input style={{ marginBottom: 8 }} placeholder="Search" onChange={evt => handleLookupChange(evt)} />
              {
                loading ? (
                  null
                ) : (
                  <Item label="Select contacts">
                    {/* Todo: change to tree select */}
                    <Tree
                      className={styles.treeContainer}
                      treeData={treeData}
                      checkable
                      multiple
                      onCheck={onCheck}
                      checkedKeys={checkedKeys}
                    />
                  </Item>
                )
              }
              <Button type="link" onClick={() => { setCheckedKeys([]) }}>Deselect all</Button>
            </Card>
          </Col>
        </Row>
      </Form>
    </Content>
  )
}