export const colors = {
  antique: '#FFF9F2',
  green: '#9ac325',
  blue: '#1890ff',
  gray: '#6C7275',
  red: '#FF322C',
  orange: '#faad14',
  black: "#393939",
  white: "#FFFFFF",
  light: "#f1f1f1",
  lowRating: "#D6DA30"
};

export type Color = 'antique' | 'green' | 'blue' | 'gray' | 'red' | 'orange' | 'black' | 'white' | 'lowRating'
