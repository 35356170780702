import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { QuoteStatus } from "../../graphql/globalTypes";
import { UPDATE_QUOTE } from "../../graphql/mutation/Quote";
import { UpdateQuote as UpdateQuoteData, UpdateQuoteVariables } from "../../graphql/mutation/Quote/__generated__/UpdateQuote";
import { QUOTE } from "../../graphql/query/Quote";
import { Quote, QuoteVariables } from "../../graphql/query/Quote/__generated__/Quote";
import { displayErrorMessage, displaySuccessNotification, sentenceCase } from "../../utils";

interface Props {
  setModalVisible: (id: string | null) => void
  quoteId?: string | null
}

const {Item} = Form;
const {Title, Text} = Typography;
const {Option} = Select;

export const UpdateQuote: React.FC<Props> = ({setModalVisible, quoteId}) => {
  const {data} = useQuery<Quote, QuoteVariables>(QUOTE, {
    variables: {
      id: quoteId as string
    }
  })
  const [updateQuote] = useMutation<UpdateQuoteData, UpdateQuoteVariables>(UPDATE_QUOTE, {
    onCompleted: data => {
      if(data) {
        displaySuccessNotification("Quote successfully updates")
      }
    },
    onError: data => {
      if(data) {
        displayErrorMessage("Something went wrong. Please try again later")
      }
    }
  })

  if(!quoteId) {
    return null;
  }

  const handleUpdateAction = (input: any) => {
    updateQuote({
      variables: {
        input: {
          id: quoteId,
          status: input.status,
          purchaseOrder: input.purchaseOrder
        }
      }
    })
    setModalVisible(null)
  }

  return (
    <Modal
      visible={Boolean(quoteId)}
      onCancel={() => setModalVisible(null)}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <Title level={2}>Update Quote</Title>
      <Form initialValues={data?.quote} layout="vertical" onFinish={handleUpdateAction}>
        <Row>
          <Col span={8}>
            <Title level={5}>Date:</Title>
            <Text>{data?.quote.createdAt && dayjs(data?.quote.createdAt).format("DD-MM-YYYY")}</Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Status:</Title>
            <Text>{data?.quote.status && sentenceCase(data?.quote.status as string)}</Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Reference:</Title>
            <Text>{data?.quote.reference}</Text>
          </Col>
        </Row>
        <span>
          <Title level={5}>Site:</Title>
          <Text>{data?.quote.site?.name},</Text>
          <Text>{data?.quote.site?.street}</Text>
        </span>
        <span>
          <Title level={5}>Note:</Title>
          <Text>{data?.quote.note} </Text>
        </span>
        <span>
          <Title level={5}>Download Quote</Title>
          <Button>Quote</Button>
        </span>
        <Row gutter={24}>
          <Col span={12}>
            <Item name="status" label="Accept/Decline Quote">
              <Select>
                <Option value={QuoteStatus.PENDING}>Pending</Option>
                <Option value={QuoteStatus.ACCEPTED}>Accept</Option>
                <Option value={QuoteStatus.DECLINED}>Decline</Option>
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item name="purchaseOrder" label="Purchase Order Number">
              <Input />
            </Item>
          </Col>
        </Row>
        <Item>
            <Button type="primary" htmlType="submit">Update</Button>
        </Item>
      </Form>
    </Modal>    
  )
}