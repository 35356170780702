import React from "react";
import { Form, Modal, Select } from "antd";
import { Rating } from "../../graphql/globalTypes";
import { Button } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { SetSiteRiskRating, SetSiteRiskRatingVariables } from "../../graphql/mutation/Site/__generated__/SetSiteRiskRating";
import { UPDATE_SITE_RATING } from "../../graphql/mutation/Site";
import { displayErrorMessage, displaySuccessNotification } from "../../utils";

interface Props {
  visible: boolean;
  onClose: () => void;
  currentRating?: Rating | string
  siteId: string
}

const {Item} = Form;
const {Option} = Select;

export const UpdateSiteRatingModal: React.FC<Props> = ({visible, onClose, currentRating, siteId}) => {

  const [updateSiteRating] = useMutation<SetSiteRiskRating, SetSiteRiskRatingVariables>(UPDATE_SITE_RATING, {
    onCompleted(data) {
      if(data && data.setSiteRiskRating) {
        displaySuccessNotification("Site risk rating updated successfully");
      }
    },
    onError(error) {
      if(error) {
        displayErrorMessage('Error updating site risk rating');
      }
    },
  });

  const onFinish = (values: any) => {
    updateSiteRating({variables: {
      id: siteId,
      riskRating: values.rating
    }})
  }

  return (
    <Modal visible={visible} onCancel={onClose} footer={false}>
      <h2>Update Site Risk Rating</h2>
      <Form layout="vertical" initialValues={{rating: currentRating}} onFinish={onFinish}>
        <Item name="rating" label="Rating">
          <Select>
            <Option value="LOW">Low</Option>
            <Option value="MEDIUM">Medium</Option>
            <Option value="HIGH">High</Option>
            <Option value="VERYHIGH">Very High</Option>
          </Select>
        </Item>
        <Item>
          <Button type="primary" htmlType="submit">Update</Button>
        </Item>
      </Form>
    </Modal>
  )
}