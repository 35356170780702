import { gql } from "apollo-boost";

export const PENDING_ESCALATION = gql`
  query PendingEscalations($limit: Int!, $page: Int!) {
    pendingEscalations(limit: $limit, page: $page) {
      total
      result {
        id
        site {
          id
          name
          ratings {
            id
            riskRating
            pestRisk
            date
          }
        }
        createdAt
      }
    }
  }
`;