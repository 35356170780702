import { gql } from "apollo-boost";

export const COMPLIANCE_FILES = gql`
  query ComplianceFiles($limit: Int!, $page: Int!) {
    complianceFiles(limit: $limit, page: $page) {
      total
      result {
        id
        fileName
        size
        type
        createdAt
      }
    }
  }
`;