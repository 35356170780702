import { createUseStyles } from 'react-jss';

export const loadingPageStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%"
  }
})