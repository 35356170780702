import { Typography } from 'antd';
import React from 'react';
import { colors } from '../../../styles';
import { Rating } from '../../graphql/globalTypes';
import { circleRatingStyles } from './styles';

interface Props {
  backgroundColor?: string,
  title?: string | null,
  selected?: boolean,
  size?: number
}

const {Text} = Typography;

export const CircleRating: React.FC<Props> = ({backgroundColor = colors.black, title, selected = false, size = 70}) => {
  let text = "No Risk Yet";
  let color = colors.green;

  switch(title) {
    case Rating.VERYHIGH :
      text = "Very High"
      color = colors.black
      break;
    case Rating.HIGH :
      text = "High"
      color = colors.red
      break;
    case Rating.MEDIUM :
      text = "Medium"
      color = colors.orange
      break;
    case Rating.LOW :
      text = "Low"
      color = colors.lowRating
      break;
    
  }
  
  const styles = circleRatingStyles({color, selected, size});

  return (
    <div className={styles.container}>
      <Text className={styles.title}>{text}</Text>
    </div> 
  )
}