import { Col, Row, Typography } from "antd";
import React from "react";
import { ReactComponent as Bird } from '../../../../../../../../assets/bird.svg';
import { ReactComponent as FlyingInsect } from '../../../../../../../../assets/flyingInsect.svg';
import { ReactComponent as Mouse } from '../../../../../../../../assets/mouse.svg';
import { ReactComponent as Rat } from '../../../../../../../../assets/rat.svg';
import { Rating } from "../../../../../../../../lib/graphql/globalTypes";
import { getRiskRatingColor } from "../../../../../../../../lib/utils";
import { base, spacing } from "../../../../../../../../styles";
import { pestRatingStyles } from "./styles";

interface Props {
  pestRisk: string[] | null
  riskRating: Rating
}

const {Title, Text} = Typography

export const PestRating: React.FC<Props> = ({riskRating, pestRisk}) => {
  const styles = pestRatingStyles();

  return (
    <Row>
      <Col className={styles.container}>
        <Title level={3}>Pests Found:</Title>
        <Text style={{fontWeight: "600", color: getRiskRatingColor(riskRating)}}>{pestRisk}</Text>
      </Col>
      <Col style={{marginLeft: spacing.medium, display: "flex", flexDirection: "row"}} className={styles.container}>
        {
          pestRisk && pestRisk.length && pestRisk[0] !== "No Activity Found" ? (
            <>
            {
              pestRisk[0].includes("Mice") && <Mouse style={{fill: getRiskRatingColor(riskRating)}} width={base(3)} />
            }
            {
              pestRisk[0].includes("Rats") && <Rat style={{fill: getRiskRatingColor(riskRating)}} width={base(3)} />
            }
            {/* {
              pestRisk[0].includes("Moths") && <Insect style={{fill: getRiskRatingColor(riskRating)}} width={base(2)} />
            } */}
            {
              (
                pestRisk[0].includes("Wasps") 
                || pestRisk[0].includes("Bees") 
                || pestRisk[0].includes("Flies") 
                || pestRisk[0].includes("Hornet")
                || pestRisk[0].includes("Mosquitoes")
                || pestRisk[0].includes("Moths")
                ) && <FlyingInsect style={{fill: getRiskRatingColor(riskRating)}} width={base(2)} />
            }
            {
              (
                pestRisk[0].includes("Birds") 
                || pestRisk[0].includes("Pigeons") 
                || pestRisk[0].includes("Glis Glis") 
                || pestRisk[0].includes("Pigeon")
                ) && <Bird style={{fill: getRiskRatingColor(riskRating)}} width={base(2)} />
            }
            </>
          ): (
            null
          )
        }
      </Col>
    </Row>
  )
}