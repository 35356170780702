import { useQuery } from "@apollo/react-hooks";
import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CONTACTS } from "../../../../lib/graphql/query/Contact";
import { Contacts as ContactsData, ContactsVariables, Contacts_contacts_result } from "../../../../lib/graphql/query/Contact/__generated__/Contacts";
import { contactStyles } from "../../styles";
interface Props {
  refresh: number
  setRefresh: (i: number) => void
}

export const ActiveContactsTable: React.FC<Props> = ({refresh, setRefresh}) => {
  const styles = contactStyles();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [lookup, setLookup] = useState<string>("")
  const {data, loading, refetch} = useQuery<ContactsData, ContactsVariables>(CONTACTS, {
    variables: {
      active: true,
      limit,
      page,
      lookup,
    }
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Contact Dashboard",
      render: (record: Contacts_contacts_result) => <Link to={`/contact/${record.contactId}`} >Visit Dashboard</Link>
    }
  ]

  const handlePaginationChange = (page: number, size: number) => {
		setPage(page);
		setLimit(size);
	};

  useEffect(() => {
    refetch()
  }, [refresh, refetch])

  const handleLookupChange = (evt: any) => {
		setLookup(evt.target.value);
	};

  return (
    <>
      <Input className={styles.searchInput} placeholder="Search" onChange={evt => handleLookupChange(evt)} />
      <Table 
        columns={columns}
        dataSource={data?.contacts.result}
        loading={loading}
        pagination={{
          defaultPageSize: limit,
          defaultCurrent: page,
          total: data?.contacts.total,
          onChange: handlePaginationChange,
        }}
      />
    </>
  )
}