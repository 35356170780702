import { gql } from "apollo-boost";

export const ADD_SITE_PLAN = gql`
  mutation AddSitePlan($input: AddSitePlanInput!) {
    addSitePlan(input: $input) {
      id
    }
  }
`

export const SITE_PLAN = gql`
  mutation SitePlan($id: String!) {
    sitePlan(id: $id) {
      file
      fileName
      type
    }
  }
`;