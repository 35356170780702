import { createUseStyles } from "react-jss";
import { colors, spacing } from "../../styles";

export const LoginStyles = createUseStyles({
  container: {
    // display: "flex",
    flexDirection: "row",
    display: 'none'
  },
  innerContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: colors.white
  },
  logoContainer: {
    paddingTop: spacing.small,
    paddingBottom: spacing.small,
    display: "flex",
    justifyContent: "center"
  },
  logo: {
    fill: colors.green,
  }
})