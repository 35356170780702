import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Col, DatePicker, Form, Layout, Row, Steps, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { GENERATE_SITE_REPORT } from "../../../../lib/graphql/mutation/Report";
import { GenerateSiteReport as GenerateSiteReportData, GenerateSiteReportVariables } from "../../../../lib/graphql/mutation/Report/__generated__/GenerateSiteReport";
import { reportsStyles } from "./styles";

interface Props {
  siteId: string | undefined
}

const {Content} = Layout
const {Title} = Typography;
const {Step} = Steps
const {Item} = Form;

export const GenerateSiteReport: React.FC<Props> = ({siteId}) => {
  const [generateReport] = useMutation<GenerateSiteReportData, GenerateSiteReportVariables>(GENERATE_SITE_REPORT, {
    onCompleted: (data) => {
      if(data && data.generateSiteReport) {
        setStep(2)
        let fetchDataModified = `data:application/xlsx;base64,${data.generateSiteReport}`
        let a = document.createElement("a")
        a.href = fetchDataModified
        a.download = `overview-report-${dayjs().format("DD-MM-YYYY")}.xlsx`
        a.click()
      }
    }
  })
  const [step, setStep] = useState(0)
  const styles = reportsStyles();

  const handleSubmit = (input: any) => {
    setStep(1)
    generateReport({
      variables: {
        input: {
          id: siteId,
          ...input
        }
      }
    })
  }

  return (
    <Content className={styles.container}>
      <Row gutter={24}>
        <Col lg={12} xs={24}>
          <Card>
          <Title>Generate A Report</Title>
            <Steps current={step}>
              <Step title="Start" />
              <Step title="In Progress" subTitle="Please wait" />
              <Step title="Complete"/>
            </Steps><br />
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row gutter={24}>
                <Col span={12}>
                  <Item name="startDate" label="Start Date" rules={[{required: true, message: "Start date is required"}]}>
                    <DatePicker />
                  </Item>
                </Col>
                <Col span={12}>
                  <Item name="endDate" label="End Date" rules={[{required: true, message: "End date is required"}]}>
                    <DatePicker />
                  </Item>
                </Col>
              </Row>
              <Item>
                <Button type="primary" htmlType="submit">Generate</Button>
              </Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Content>
  )
}