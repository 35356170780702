import { base } from "./base";

export const strokeWidth = '3px';

export const headerHeight = base(7);

export const spacing = {
  xSmall: base(0.5),
  small: base(1),
  medium: base(2),
  large: base(4)
}