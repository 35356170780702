import { Typography } from "antd";
import React from "react";
import { categoryCardStyles } from "./styles";

interface Props {
  title: string
  value: string | number
  icon: React.ReactNode
}

const {Text} = Typography;

export const CategoryCard: React.FC<Props> = ({title, value, icon}) => {
  const styles = categoryCardStyles();

  return (
    <div className={styles.container}>
      <span className={styles.iconContainer}>
        {icon}
      </span>
      <Text className={styles.text}>{value}</Text>
      <Text className={styles.text}>{title}</Text>
    </div>
  )
}